import { mapIOErrorToLocalizedObject } from '@eversports/core-io/helpers'
import { isIOError } from '@eversports/io-error'
import Checkbox from '@eversports/react-components/primitives/Checkbox'
import Flex, { VerticalFlex } from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import { makeStyles } from '@mui/styles'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import PyroForm, { Form } from 'pyro-form'
import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import Address from '../../components/Address'
import { getCountryCallingCodeByIso } from '../../components/PhoneNumberField/get-country-calling-codes'
import StepperActions from '../../components/StepperActions'
import { Localized, useIntl } from '../../localization/react'
import RegistrationContext, { RegistrationVenueProps } from '../../registration-context'
import { RegistrationRoutes } from '../../routes'

import check from './check'

const useStyles = makeStyles({
  brandQuestion: {
    display: 'inline-block',
    marginTop: 0,
  },
  brandHeadline: {
    marginTop: '2.5em',
    marginBottom: '1em',
  },
})

const RegistrationVenue: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history, location }) => {
  const intl = useIntl()
  const [errors, setErrors] = useState<{ address?: string; brandPromise?: string }>()
  const registrationContext = useContext(RegistrationContext)
  const classes = useStyles()

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const handleValidate = throttledPyroFormValidator((values: RegistrationVenueProps) => {
    try {
      checkAddress(values)
      checkBrandPromise(values)
      check(values)
    } catch (e: any) {
      if (isIOError(e)) {
        return mapIOErrorToLocalizedObject(e, intl)
      }
      ClientLogger.error('Venue address validation failed', e)
    }
    return {}
  })

  const checkAddress = (values: RegistrationVenueProps) => {
    const error: { address?: string } = {}
    if (!values.street || !values.streetNumber || !values.zip || !values.city || !values.country) {
      error.address = intl.invalidAddress()
    }
    setErrors(error)
  }

  const checkBrandPromise = (values: RegistrationVenueProps) => {
    const error: { brandPromise?: string } = {}
    if (!values.brandPromise || values.brandPromise.length === 0) {
      error.brandPromise = intl.invalidBrandPromise()
    }
    setErrors(error)
  }

  const handleForwardStep = (values: RegistrationVenueProps) => {
    registrationContext.setVenueInformation(values)
    registrationContext.legalCompanyInformation.countryCallingCode = getCountryCallingCodeByIso(values.country).code
    registrationContext.setValidStep(RegistrationRoutes.VENUE)
    history.push(`${RegistrationRoutes.LEGAL_COMPANY}${location.search}`)
  }

  return (
    <VerticalFlex verticalSpacing="wide">
      <VerticalFlex verticalSpacing="dense">
        <Heading is="h2">
          <Localized id="registration-venue-heading" />
        </Heading>
        <Text color="subtleText">
          <Localized id="registration-venue-subheading" />
        </Text>
      </VerticalFlex>
      <PyroForm
        initialValues={registrationContext.venueInformation}
        onValidate={handleValidate}
        onSubmit={handleForwardStep}
        errors={errors}
      >
        {({ values, hasErrors, handleChange }) => (
          <Form>
            <VerticalFlex verticalSpacing="wide">
              <TextField name="name" label={<Localized id="registration-venue-studio-name" />} fullWidth autoFocus />
              <Address
                autoCompleteLabel={<Localized id="registration-venue-studio-address" />}
                isAutoComplete={values.isAutoCompleteAddress}
                autoCompleteSearchValue={values.address}
                onChange={handleChange}
              />
              <TextField
                name="website"
                label={<Localized id="optional" params={{ field: <Localized id="website" /> }} />}
                fullWidth
              />
              <h2 className={classes.brandHeadline}>
                <Localized id="registration-brandpromise-headline" />
              </h2>
              <Flex flexDirection="row" alignItems="flex-start" is="label" className={classes.brandQuestion}>
                <Checkbox value="expectationImprove" name="brandPromise" />
                <Localized id="registration-brandpromise-expectationImprove" />
              </Flex>
              <Flex flexDirection="row" alignItems="flex-start" is="label" className={classes.brandQuestion}>
                <Checkbox value="expectationHelp" name="brandPromise" />
                <Localized id="registration-brandpromise-expectationHelp" />
              </Flex>
              <Flex flexDirection="row" alignItems="flex-start" is="label" className={classes.brandQuestion}>
                <Checkbox value="expectationPartner" name="brandPromise" />
                <Localized id="registration-brandpromise-expectationPartner" />
              </Flex>
              <Flex flexDirection="row" alignItems="flex-start" is="label" className={classes.brandQuestion}>
                <Checkbox value="expectationOther" name="brandPromise" />
                <Localized id="registration-brandpromise-expectationOther" />
              </Flex>
              {values.brandPromise.indexOf('expectationOther') >= 0 && (
                <TextField
                  name="brandPromiseText"
                  fullWidth
                  label={<Localized id="registration-brandpromise-other-label" />}
                />
              )}
              <StepperActions isForwardActionDisabled={hasErrors} />
            </VerticalFlex>
          </Form>
        )}
      </PyroForm>
    </VerticalFlex>
  )
}

export default withRouter(RegistrationVenue)
