import { assertNever } from '@eversports/utility-types'
import React from 'react'

import { Localized } from '../../localization/react'
import { RegistrationRoutes } from '../../routes'

interface Props {
  step: RegistrationRoutes
}

const StepperLabelContent: React.FC<React.PropsWithChildren<Props>> = ({ step }) => {
  switch (step) {
    case RegistrationRoutes.VENUE:
      return <Localized id="registration-venue-heading" />
    case RegistrationRoutes.LEGAL_COMPANY:
      return <Localized id="registration-legal-company-heading" />
    case RegistrationRoutes.BILLING_AND_FINANCIAL:
      return <Localized id="registration-billing-and-financial-heading" />
    case RegistrationRoutes.CREATE_AN_ADMIN_USER:
      return <Localized id="registration-create-an-admin-user-heading" />
    case RegistrationRoutes.TERMS_AND_CONDITIONS:
      return <Localized id="registration-terms-and-conditions-heading" />
    case RegistrationRoutes.COMPLETE_SET_UP:
      return <Localized id="registration-complete-set-up-heading" />
    default:
      return assertNever(step)
  }
}

export default StepperLabelContent
