import { Query } from '@apollo/client/react/components'
import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import MenuItem from '@eversports/react-components/primitives/MenuItem'
import Placeholder from '@eversports/react-components/primitives/Placeholder'
import Select from '@eversports/react-components/primitives/Select'
import React from 'react'

import { Localized } from '../localization/react'
import { SelectCountryCountriesDocument, SelectCountryCountriesQuery } from '../graphql'

const SelectCountry: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Query<SelectCountryCountriesQuery> query={SelectCountryCountriesDocument}>
    {({ error, data }) => {
      if (error) {
        return <>{error.message}</>
      }
      if (data) {
        return (
          <Select name="country" label={<Localized id="address-country" />} required fullWidth>
            {data.countries.map((country, index) => (
              <MenuItem key={`${country.iso}-${index}`} value={country.iso}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        )
      }

      return (
        <Placeholder height={250} width="100%" center>
          <CircularProgress />
        </Placeholder>
      )
    }}
  </Query>
)

export default SelectCountry
