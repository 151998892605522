import React from 'react'

export interface FreeTrialVenueProps {
  companyName: string
  studioTypes: Array<string>
  country: string
  website: string
}
export interface FreeTrialCreateAnAdminUserProps {
  firstName: string
  lastName: string
  countryCallingCode: string
  phoneNumber: string
  email: string
  password: string
  question: string
}

export interface FreeTrialProps {
  venueValues: FreeTrialVenueProps
  setVenueValues: (venueValues: FreeTrialVenueProps) => void
  createAnAdminUserValues: FreeTrialCreateAnAdminUserProps
  setCreateAnAdminUserValues: (createAnAdminUserValues: FreeTrialCreateAnAdminUserProps) => void
}

const FreeTrialContext = React.createContext<undefined | FreeTrialProps>(undefined)

export default FreeTrialContext
