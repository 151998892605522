import { default as NotFoundComponent } from '@eversports/react-components/patterns/NotFound'
import Flex from '@eversports/react-components/primitives/Flex'
import React from 'react'

const NotFound: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex alignItems="center" justifyContent="center" fullWidth>
    <NotFoundComponent />
  </Flex>
)

export default NotFound
