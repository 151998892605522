import AutoComplete, {
  AutoCompleteHighLight,
  AutoCompleteSuggestion,
} from '@eversports/react-components/primitives/AutoComplete'
import { PyroFormValues } from 'pyro-form'
import React, { ReactElement, useEffect, useState } from 'react'

import { useIntl } from '../localization/react'
import { AddressProps } from '../registration-context'

interface AddressSuggestion {
  id: string
  description: string
  country: string
}

enum AddressType {
  'route' = 'street',
  'street_number' = 'streetNumber',
  'locality' = 'city',
  'postal_code' = 'zip',
  'country' = 'country',
}

export interface AutoCompleteAddressProps<Values extends PyroFormValues> {
  name: Extract<keyof Values, string>
  onChange: (values: AddressProps) => void
  searchValue?: string
  label?: string | ReactElement
  className?: string
}

interface Coord {
  latitude?: number
  longitude?: number
  accuracy?: number
  // eslint-disable-next-line no-undef
  location?: google.maps.LatLng
}

const addressValuesInitialState = {
  street: '',
  streetNumber: '',
  city: '',
  zip: '',
  country: '',
}

const AutoCompleteAddress = <Values extends PyroFormValues>({
  onChange,
  searchValue,
  name,
  label,
  className,
}: AutoCompleteAddressProps<Values>) => {
  const intl = useIntl()
  const [addressSuggestions, setAddressSuggestions] = useState<Array<AddressSuggestion>>([])
  const [coords, setCoords] = useState<Coord>()
  const [search, setSearch] = useState(searchValue)
  const autoCompleteService =
    // eslint-disable-next-line no-undef
    typeof window !== 'undefined' && window.google && new google.maps.places.AutocompleteService()
  // eslint-disable-next-line no-undef
  const geoCoder = typeof window !== 'undefined' && window.google && new google.maps.Geocoder()

  useEffect(() => {
    getGeoLocation()
  }, [coords])

  useEffect(() => {
    if (searchValue !== search) {
      onChange(addressValuesInitialState)
      getPlaceAutocomplete()
      setSearch(searchValue)
    }
  }, [searchValue])

  const getPlaceAutocomplete = () => {
    if (searchValue) {
      // eslint-disable-next-line no-undef
      const config: google.maps.places.AutocompletionRequest = {
        input: searchValue,
        types: ['address'],
      }

      if (coords && coords.location) {
        config.location = coords.location
        config.radius = coords.accuracy
      }

      if (autoCompleteService) {
        autoCompleteService.getPlacePredictions(config, (predictions) => {
          const suggestions =
            predictions &&
            predictions.map((prediction) => ({
              id: prediction.place_id,
              description: prediction.description,
              country: prediction.terms[prediction.terms.length - 1].value,
            }))

          setAddressSuggestions(suggestions)
        })
      }
    }
  }

  const getGeoLocation = () => {
    if (navigator.geolocation && !coords) {
      navigator.geolocation.getCurrentPosition(({ coords: { longitude, latitude, accuracy } }) => {
        const location =
          // eslint-disable-next-line no-undef
          typeof window !== 'undefined' ? window.google && new google.maps.LatLng(latitude, longitude) : undefined
        setCoords({ latitude, longitude, accuracy, location })
      })
    }
  }

  const getPlaceDetails = (placeId: string) => {
    if (geoCoder) {
      geoCoder.geocode({ placeId }, (result, status) => {
        if (String(status) === 'OK') {
          const addressValues: AddressProps = {
            ...addressValuesInitialState,
            longitude: result[0].geometry.location.lng(),
            latitude: result[0].geometry.location.lat(),
          }
          for (const addressComponent of result[0].address_components) {
            for (const type of addressComponent.types) {
              const addressType = AddressType[type as keyof typeof AddressType]
              if (addressType) {
                addressValues[addressType] =
                  addressType === AddressType.country ? addressComponent.short_name : addressComponent.long_name
              }
            }
          }
          onChange(addressValues)
        }
      })
    }
  }

  return (
    <AutoComplete
      name={name}
      label={label}
      suggestions={addressSuggestions}
      defaultValue={searchValue}
      filterBy="description"
      placeholder={intl.startTypingForGoogleSearch()}
      className={className}
    >
      {({ suggestions, onClick, query }) =>
        suggestions.map((suggestion) => (
          <AutoCompleteSuggestion
            key={suggestion.id}
            onClick={() => {
              onClick(suggestion.id)
              getPlaceDetails(suggestion.id)
            }}
          >
            <AutoCompleteHighLight suggestion={suggestion.description} query={query} />
          </AutoCompleteSuggestion>
        ))
      }
    </AutoComplete>
  )
}

export default AutoCompleteAddress
