import { mapIOErrorToLocalizedObject } from '@eversports/core-io/helpers'
import { isIOError } from '@eversports/io-error'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import StepperActions from '../../components/StepperActions'
import TextFieldPassword from '../../components/TextFieldPassword'
import { Localized, useIntl } from '../../localization/react'
import RegistrationContext, { RegistrationCreateAnAdminUserProps } from '../../registration-context'
import { RegistrationRoutes } from '../../routes'

import check from './check'
import initialValues from './initial-values'

const useStyles = makeStyles({
  field: {
    width: '50%',
  },
})

const RegistrationCreateAnAdminUser: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history }) => {
  const intl = useIntl()
  const classes = useStyles()
  const registrationContext = useContext(RegistrationContext)

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const handleValidate = throttledPyroFormValidator((values: RegistrationCreateAnAdminUserProps) => {
    try {
      check(values)
    } catch (e: any) {
      if (isIOError(e)) {
        return mapIOErrorToLocalizedObject(e, intl)
      }
      ClientLogger.error('Admin user creation failed', e)
    }
    return {}
  })

  const handleForwardStep = (values: RegistrationCreateAnAdminUserProps) => {
    registrationContext.setUserAdminInformation(values)
    registrationContext.setValidStep(RegistrationRoutes.CREATE_AN_ADMIN_USER)
    history.push(`${RegistrationRoutes.TERMS_AND_CONDITIONS}${window.location.search}`)
  }

  const handleBackStep = () => {
    history.push(`${RegistrationRoutes.BILLING_AND_FINANCIAL}${window.location.search}`)
  }

  return (
    <Flex flexDirection="column" verticalSpacing="wide" fullWidth>
      <Flex flexDirection="column" verticalSpacing="dense">
        <Heading is="h2">
          <Localized id="registration-create-an-admin-user-heading" />
        </Heading>
        <Text color="subtleText">
          <Localized id="registration-create-an-admin-user-subheading" />
        </Text>
      </Flex>
      <PyroForm
        initialValues={initialValues(registrationContext)}
        onValidate={handleValidate}
        onSubmit={handleForwardStep}
      >
        {({ hasErrors }) => (
          <Form>
            <Flex flexDirection="column" verticalSpacing="wide">
              <Flex horizontalSpacing="default" fullWidth>
                <Flex className={classes.field}>
                  <TextField name="firstname" label={<Localized id="user-first-name" />} fullWidth autoFocus />
                </Flex>
                <Flex className={classes.field}>
                  <TextField name="lastname" label={<Localized id="user-last-name" />} fullWidth />
                </Flex>
              </Flex>
              <TextField
                name="email"
                disabled={!!registrationContext.userAdminInformation.email}
                label={<Localized id="user-email" />}
                fullWidth
              />
              <TextFieldPassword />
              <StepperActions isForwardActionDisabled={hasErrors} onGoBack={handleBackStep} />
            </Flex>
          </Form>
        )}
      </PyroForm>
    </Flex>
  )
}

export default withRouter(RegistrationCreateAnAdminUser)
