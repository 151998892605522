import { IOError } from '@eversports/io-error'
import isEmail from 'validator/lib/isEmail'

import { MarkValidatorFn, ValidatorFn } from './types'

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const validator: MarkValidatorFn = (fn) => fn as any

export function email(): ValidatorFn<string> {
  const error = IOError.factory('invalid-email')
  return (input) => {
    if (!isEmail(input)) {
      throw error(input)
    }
    return input
  }
}

export function password(): ValidatorFn<string> {
  const error = IOError.factory('invalid-password')
  return (input) => {
    if (input.length < 8) {
      throw error(input)
    }
    return input
  }
}

export function required(): ValidatorFn<string> {
  const error = IOError.factory('required')
  return (input) => {
    if (input === '' || input === undefined || input === null) {
      throw error(input)
    }
    return input
  }
}

interface PhoneOptions {
  withoutCountryCode?: boolean
}
export function telephone({ withoutCountryCode = false }: PhoneOptions = {}): ValidatorFn<string> {
  const error = IOError.factory('invalid-phone')
  return (input) => {
    // NOTE(swatinem): this is copied from `utilities`, to avoid importing the
    // the whole thing. The check itself would need a lot of improvement anyway
    if (withoutCountryCode && !input.match(/^[0-9 /()-]{3,30}$/)) {
      throw error(input)
    }
    if (!withoutCountryCode && !input.match(/^\+?[0-9 /()-]{5,30}$/)) {
      throw error(input)
    }
    return input
  }
}

interface FiscalCodeOptions {
  countryCode: string
}

export function fiscalCode(_codeOptions: FiscalCodeOptions): ValidatorFn<string> {
  // const error = IOError.factory('invalid-fiscal-code')
  return (input) =>
    // let regex: RegExp | null = null
    // TODO: Due to us currently allowing user from all over europe to book
    // different activities in our app, we actually don't wanna restrict the user
    // to have to introduce a valid fiscal code for the facility country, which in
    // this case includes only ES and IT. For that reason, we for now disable
    // the fiscal code validation

    input

  // switch (countryCode) {
  //   case 'IT':
  //     regex = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/g
  //     break
  //   case 'ES':
  //     regex = /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/g
  //     break
  //   default:
  //     throw new Error(`The country ${countryCode} doesn't support fiscal code validation.`)
  // }

  // if (!input.match(regex)) {
  //   throw error(input)
  // }

  // return input
}

interface LengthOptions {
  min?: number
  max?: number
}
export function length({ min = 0, max = Infinity }: LengthOptions): ValidatorFn<string> {
  const options = { min, max }
  const minError = IOError.factory('invalid-min-length', options)
  const maxError = IOError.factory('invalid-max-length', options)
  return (input) => {
    // eslint-disable-next-line no-shadow
    const { length } = input
    if (length < min) {
      throw minError(input)
    }
    if (length > max) {
      throw maxError(input)
    }
    return input
  }
}

interface NumberOptions {
  min?: number
  max?: number
}
export function number({ min = 0, max = Infinity }: NumberOptions): ValidatorFn<number> {
  const options = { min, max }
  const minError = IOError.factory('invalid-min-value', options)
  const maxError = IOError.factory('invalid-max-value', options)
  return (input) => {
    if (input < min) {
      throw minError(input)
    }
    if (input > max) {
      throw maxError(input)
    }
    return input
  }
}

interface ArrayOptions<T> {
  element?: ValidatorFn<T>
  min?: number
  max?: number
}
export function array<T = any>({ element = (e) => e, min = 0, max = Infinity }: ArrayOptions<T> = {}): ValidatorFn<
  Array<T>
> {
  const options = { min, max }
  const minError = IOError.factory('invalid-min-array-length', options)
  const maxError = IOError.factory('invalid-max-array-length', options)
  const validatorFn: ValidatorFn<Array<T>> = (input) => {
    // eslint-disable-next-line no-shadow
    const { length } = input
    if (length < min) {
      throw minError(input)
    }
    if (length > max) {
      throw maxError(input)
    }
    return input
  }
  validatorFn.validator = element

  return validatorFn
}
