import { HorizontalFlex, VerticalFlex } from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React, { ReactElement } from 'react'

import { Localized } from '../localization/react'
import { AddressProps } from '../registration-context'

import AutoCompleteAddress from './AutoCompleteAddress'
import ManualAddress from './ManualAddress'

const useStyles = makeStyles({
  clickHere: {
    cursor: 'pointer',
  },
})

interface AddressValues extends AddressProps {
  isAutoCompleteAddress: boolean
}

interface Props {
  isAutoComplete: boolean
  autoCompleteSearchValue?: string
  autoCompleteLabel?: string | ReactElement
  onChange: (
    name: Extract<keyof AddressValues, string>,
    value: AddressValues[Extract<keyof AddressValues, string>],
  ) => void
}

const Address: React.FC<React.PropsWithChildren<Props>> = ({
  autoCompleteSearchValue,
  isAutoComplete,
  autoCompleteLabel,
  onChange,
}) => {
  const classes = useStyles()

  const handleChange = (values: AddressProps) => {
    onChange('street', values.street)
    onChange('streetNumber', values.streetNumber)
    onChange('zip', values.zip)
    onChange('city', values.city)
    onChange('country', values.country)
    onChange('latitude', values.latitude)
    onChange('longitude', values.longitude)
  }

  return (
    <VerticalFlex verticalSpacing="default">
      {isAutoComplete ? (
        <AutoCompleteAddress
          name="address"
          label={autoCompleteLabel}
          onChange={handleChange}
          searchValue={autoCompleteSearchValue}
        />
      ) : (
        <ManualAddress />
      )}
      <HorizontalFlex horizontalSpacing="dense">
        <Text
          color="primary.main"
          size="small"
          className={classes.clickHere}
          onClick={() => onChange('isAutoCompleteAddress', !isAutoComplete)}
        >
          <Localized id="click-here" />
        </Text>
        <Text size="small">
          {isAutoComplete ? (
            <Localized id="click-here-to-fill-address-manually" />
          ) : (
            <Localized id="click-here-to-anable-autocomplete-address" />
          )}
        </Text>
      </HorizontalFlex>
    </VerticalFlex>
  )
}

export default Address
