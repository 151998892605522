import { RegistrationContextProps, RegistrationLegalCompanyProps } from '../../registration-context'

function initialValues(registrationContext: RegistrationContextProps): RegistrationLegalCompanyProps {
  return {
    ...registrationContext.legalCompanyInformation,
    address: registrationContext.legalCompanyInformation.address || registrationContext.venueInformation.address,
    street: registrationContext.legalCompanyInformation.street || registrationContext.venueInformation.street,
    streetNumber:
      registrationContext.legalCompanyInformation.streetNumber || registrationContext.venueInformation.streetNumber,
    city: registrationContext.legalCompanyInformation.address || registrationContext.venueInformation.city,
    country: registrationContext.legalCompanyInformation.address || registrationContext.venueInformation.country,
    zip: registrationContext.legalCompanyInformation.zip || registrationContext.venueInformation.zip,
    isAutoCompleteAddress: registrationContext.venueInformation.isAutoCompleteAddress,
  }
}

export default initialValues
