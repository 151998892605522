function debounceAsyncCallback<T extends (...args: Array<any>) => any>(
  func: T,
  delay: number,
): (...funcArgs: Parameters<T>) => Promise<ReturnType<T>> {
  let timeout: ReturnType<typeof setTimeout>
  return async (...args: Parameters<T>): Promise<ReturnType<T>> =>
    new Promise((resolve) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        resolve(func(...args) as Promise<ReturnType<T>>)
      }, delay)
    })
}

export default debounceAsyncCallback
