import { VerticalFlex } from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

import Header from './Header'

const useStyles = makeStyles({
  main: {
    height: '100%',
  },
})

const AppContentLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles()
  return (
    <VerticalFlex is="main" alignItems="center" verticalSpacing="wide" className={classes.main}>
      <Header />
      {children}
    </VerticalFlex>
  )
}

export default AppContentLayout
