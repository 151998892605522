import { useApolloClient } from '@apollo/client'
import debounceAsyncCallback from '@eversports/react-components/logical/debounce-async-callback'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import PyroForm, { Form } from 'pyro-form'
import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import StepperActions from '../../components/StepperActions'
import { Localized, useIntl } from '../../localization/react'
import RegistrationContext, { RegistrationBillingAndFinancialProps } from '../../registration-context'
import { RegistrationRoutes } from '../../routes'

import validateIban from './validate-iban'
import validateVat from './validate-vat'

const RegistrationBillingAndFinancial: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history }) => {
  const intl = useIntl()
  const registrationContext = useContext(RegistrationContext)
  const [errors, setErrors] = useState<{ iban?: string; uid?: string }>()
  const [isValidating, setIsValidating] = useState(false)
  const client = useApolloClient()

  const debouncedValidateIban = debounceAsyncCallback(validateIban(client), 500)
  const debouncedValidateUid = debounceAsyncCallback(validateVat(client), 500)

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const handleValidate = throttledPyroFormValidator((values: RegistrationBillingAndFinancialProps) => {
    setErrors({})
    setIsValidating(true)
    checkIban(values.iban).catch((error) => {
      ClientLogger.error('IBAN Validation Failed', error)
    })
    checkUid(values.uid).catch((error) => {
      ClientLogger.error('UID Validation Failed', error)
    })
    setIsValidating(false)
    return {}
  })

  const checkIban = async (iban?: string) => {
    if (!iban) {
      setIbanError()
      return
    }

    const result = await debouncedValidateIban({ iban })
    if (result.isError) {
      ClientLogger.error('IBAN Validation Failed', result.error)
    } else if (result.value.__typename === 'ValidateIbanResult') {
      setIbanError(result.value.isValid)
    }
  }

  const checkUid = async (vat?: string) => {
    if (!vat) {
      return
    }
    if (vat.trim().length < 8) {
      setUidError(false)
      return
    }
    const result = await debouncedValidateUid({ vat })
    if (result.isError) {
      ClientLogger.error('UID Validation Failed because of: ', result.error)
    } else if (result.value.__typename === 'ValidateVatResult') {
      setUidError(result.value.isValid)
    }
  }

  const setIbanError = (isIbanValid?: boolean) => {
    const error: { iban?: string } = {}
    if (!isIbanValid) {
      error.iban = intl.invalidIban()
      setErrors(error)
    }
  }

  const setUidError = (isUidValid?: boolean) => {
    const error: { uid?: string } = {}
    if (!isUidValid) {
      error.uid = intl.invalidUid()
      setErrors(error)
    }
  }

  const handleForwardStep = (values: RegistrationBillingAndFinancialProps) => {
    registrationContext.setBillingAndFinancialInformation(values)
    registrationContext.setValidStep(RegistrationRoutes.BILLING_AND_FINANCIAL)
    history.push(`${RegistrationRoutes.CREATE_AN_ADMIN_USER}${window.location.search}`)
  }

  const handleBackStep = () => {
    history.push(`${RegistrationRoutes.LEGAL_COMPANY}${window.location.search}`)
  }

  return (
    <Flex flexDirection="column" verticalSpacing="default">
      <Heading is="h2">
        <Localized id="registration-billing-and-financial-heading" />
      </Heading>
      <Text color="subtleText">
        <Localized id="registration-billing-and-financial-subheading-first-paragraph" />
      </Text>
      <Text color="subtleText">
        <Localized id="registration-billing-and-financial-subheading-second-paragraph" />
      </Text>
      <PyroForm
        initialValues={registrationContext.billingAndFinancialInformation}
        onSubmit={handleForwardStep}
        onValidate={handleValidate}
        errors={errors}
      >
        {({ hasErrors, values }) => (
          <Form>
            <Flex flexDirection="column" verticalSpacing="wide">
              <TextField
                name="iban"
                label={<Localized id="registration-billing-and-financial-iban" />}
                fullWidth
                autoFocus
              />
              <TextField
                name="bic"
                label={
                  <Localized
                    id="optional"
                    params={{ field: <Localized id="registration-billing-and-financial-bic" /> }}
                  />
                }
                fullWidth
              />
              <Flex flexDirection="column" verticalSpacing="dense">
                <TextField
                  name="uid"
                  label={
                    <Localized
                      id="optional"
                      params={{
                        field: (
                          <Localized
                            id="registration-billing-and-financial-uid"
                            params={{ country: registrationContext.venueInformation.country }}
                          />
                        ),
                      }}
                    />
                  }
                  fullWidth
                />
                {!values.uid && (
                  <Text size="x-small" color="subtleText">
                    <Localized
                      id="registration-billing-and-financial-uid-info"
                      params={{ country: registrationContext.venueInformation.country }}
                    />
                  </Text>
                )}
              </Flex>
              <StepperActions isForwardActionDisabled={hasErrors || isValidating} onGoBack={handleBackStep} />
            </Flex>
          </Form>
        )}
      </PyroForm>
    </Flex>
  )
}

export default withRouter(RegistrationBillingAndFinancial)
