import React, { useState } from 'react'

import RegistrationContext, {
  RegistrationBillingAndFinancialProps,
  RegistrationCreateAnAdminUserProps,
  RegistrationLegalCompanyProps,
  RegistrationTermsAndConditionsProps,
  RegistrationVenueProps,
  Step,
} from '../registration-context'
import { RegistrationRoutes } from '../routes'

const RegistrationContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // Process Step
  const routes = Object.values(RegistrationRoutes)
  const [steps, setSteps] = useState<Array<Step>>(
    routes.slice(0, routes.length - 1).map((route) => ({ id: route, isValid: false })),
  )

  // Venue Values
  const [venueInformation, setVenueInformation] = useState<RegistrationVenueProps>({
    address: '',
    city: '',
    country: '',
    isAutoCompleteAddress: true,
    name: '',
    street: '',
    streetNumber: '',
    website: '',
    zip: '',
    brandPromise: [],
    brandPromiseText: '',
  })

  // User Admin Information
  const [userAdminInformation, setUserAdminInformation] = useState<RegistrationCreateAnAdminUserProps>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  })

  // Billing And Financial Information
  const [billingAndFinancialInformation, setBillingAndFinancialInformation] =
    useState<RegistrationBillingAndFinancialProps>({
      iban: '',
      bic: '',
      uid: '',
    })
  // Terms and Conditions Information
  const [termsAndConditionsInformation, setTermsAndConditionsInformation] =
    useState<RegistrationTermsAndConditionsProps>({
      firstname: '',
      lastname: '',
      role: '',
    })

  // Legal Company Information
  const [legalCompanyInformation, setLegalCompanyInformation] = useState<RegistrationLegalCompanyProps>({
    isAutoCompleteAddress: true,
    name: '',
    email: '',
    countryCallingCode: '+43',
    phoneNumber: '',
    address: '',
    city: '',
    country: '',
    street: '',
    streetNumber: '',
    zip: '',
  })

  const setValidStep = (stepId: RegistrationRoutes) => {
    const updatedSteps = steps.map((step) => {
      if (step.id === stepId) {
        return { ...step, isValid: true }
      }
      return step
    })
    setSteps(updatedSteps)
  }

  return (
    <RegistrationContext.Provider
      value={{
        venueInformation,
        setVenueInformation,
        userAdminInformation,
        setUserAdminInformation,
        billingAndFinancialInformation,
        setBillingAndFinancialInformation,
        termsAndConditionsInformation,
        setTermsAndConditionsInformation,
        legalCompanyInformation,
        setLegalCompanyInformation,
        steps,
        setValidStep,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}

export default RegistrationContextProvider
