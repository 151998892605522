import { useGoogleRecaptchaAPI } from '@eversports/react-components/logical/useGoogle'
import React, { useEffect, useRef } from 'react'

interface Props {
  siteKey: string
  onSuccessfulResponse?: () => void
}

const GoogleRecaptcha: React.FC<React.PropsWithChildren<Props>> = ({ siteKey, onSuccessfulResponse }) => {
  const googleRecaptchaAPI = useGoogleRecaptchaAPI()
  const recaptchaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!googleRecaptchaAPI || googleRecaptchaAPI.type !== 'success' || !recaptchaRef || !recaptchaRef.current) return
    googleRecaptchaAPI.grecaptcha.render(recaptchaRef.current, {
      sitekey: siteKey,
      callback: onSuccessfulResponse,
    })
  }, [googleRecaptchaAPI])

  return <div ref={recaptchaRef} />
}

export default GoogleRecaptcha
