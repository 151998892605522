import { createStyles, withStyles } from '@mui/styles'
import React from 'react'
import OpenSansBoldWoff from '../../assets/opensans-bold.woff'
import OpenSansBoldWoff2 from '../../assets/opensans-bold.woff2'
import OpenSansRegularWoff from '../../assets/opensans-regular.woff'
import OpenSansRegularWoff2 from '../../assets/opensans-regular.woff2'

// Without the anonymous function inside createStyles, the following type error is displayed:
// "Property ''@font-face'' is incompatible with index signature."
const useStyles = createStyles(() => ({
  '@global': {
    html: {
      fontFamily: "'Open Sans', sans-serif",
    },
    '@font-face': [
      {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `local('Open Sans Regular'), local('OpenSans-Regular'),
              url(${OpenSansRegularWoff2}) format('woff2'),
              url(${OpenSansRegularWoff}) format('woff')`,
      },
      {
        fontFamily: 'Open Sans',
        src: `local('Open Sans Bold'), local('OpenSans-Bold'),
              url(${OpenSansBoldWoff2}) format('woff2'),
              url(${OpenSansBoldWoff}) format('woff')`,
        fontWeight: 700,
        fontStyle: 'normal',
      },
    ],
  },
}))

const ResetFontCSS: React.FC<React.PropsWithChildren<unknown>> = () => null

export default withStyles(useStyles)(ResetFontCSS)
