import { mapIOErrorToLocalizedObject } from '@eversports/core-io/helpers'
import { isIOError } from '@eversports/io-error'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import PyroForm, { Form } from 'pyro-form'
import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import SelectCountry from '../../components/SelectCountry'
import SelectStudioTypes from '../../components/SelectStudioTypes'
import StepperActions from '../../components/StepperActions'
import FreeTrialContext, { FreeTrialVenueProps } from '../../free-trial-context'
import { Localized, useIntl } from '../../localization/react'
import { FreeTrialRoutes } from '../../routes'

import check from './check'

const FreeTrialVenue: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history }) => {
  const intl = useIntl()
  const freeTrialContext = useContext(FreeTrialContext)

  if (!freeTrialContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const handleValidate = throttledPyroFormValidator((values: FreeTrialVenueProps) => {
    try {
      check(values)
    } catch (e: any) {
      if (isIOError(e)) {
        return mapIOErrorToLocalizedObject(e, intl)
      }
      ClientLogger.error('Free trial creation failed', e)
    }
    return {}
  })

  const handleForwardStep = (values: FreeTrialVenueProps) => {
    freeTrialContext.setVenueValues(values)
    history.push(FreeTrialRoutes.CREATE_AN_ADMIN_USER)
  }

  return (
    <Flex flexDirection="column" verticalSpacing="wide" fullWidth>
      <Flex flexDirection="column" verticalSpacing="dense">
        <Heading is="h2">
          <Localized id="free-trial-title" />
        </Heading>
        <Text color="subtleText">
          <Localized id="free-trial-subheading" />
        </Text>
      </Flex>
      <PyroForm initialValues={freeTrialContext.venueValues} onValidate={handleValidate} onSubmit={handleForwardStep}>
        {({ hasErrors }) => (
          <Form>
            <Flex flexDirection="column" verticalSpacing="wide">
              <TextField name="companyName" label={<Localized id="company-name" />} fullWidth />
              <SelectStudioTypes />
              <SelectCountry />
              <TextField name="website" label={<Localized id="website" />} fullWidth />
              <StepperActions isForwardActionDisabled={hasErrors} />
            </Flex>
          </Form>
        )}
      </PyroForm>
    </Flex>
  )
}

export default withRouter(FreeTrialVenue)
