import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { PyroFormValues, usePyroField } from 'pyro-form'
import * as React from 'react'
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'

import { Theme } from '../../design-tokens/theme'
import useDebounce from '../../logical/useDebounce'
import useThrottle from '../../logical/useThrottle'
import Icon, { Icons } from '../Icon'
import TextField from '../TextField'

import { AutoCompleteSuggestionProps } from './AutoCompleteSuggestionContext'
import AutoCompleteSuggestions, { AutoCompleteSuggestionsProps } from './AutoCompleteSuggestions'

const useVariant = makeStyles(({ fontSizing }: Theme) => ({
  small: {
    height: 35,
    fontSize: fontSizing.small,
  },
  default: {
    height: 56,
  },
}))

const useStyles = makeStyles(({ layoutSpacing }: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    zIndex: 2,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    height: 'auto',
    flexGrow: 1,
    paddingTop: layoutSpacing.none,
    paddingBottom: layoutSpacing.none,
  },
  suggestions: {
    position: 'absolute',
    zIndex: 1,
    marginTop: layoutSpacing.dense,
    left: layoutSpacing.none,
    right: layoutSpacing.none,
  },
}))

type Variant = keyof ReturnType<typeof useVariant>
interface Props<
  Values extends PyroFormValues,
  Name extends Extract<keyof Values, string>,
  Suggestion extends AutoCompleteSuggestionProps,
  Filter extends keyof Suggestion,
> extends Omit<AutoCompleteSuggestionsProps<Suggestion>, 'onClick' | 'query'> {
  name: Name
  placeholder?: string
  label?: string | ReactElement
  filterBy: Filter
  variant?: Variant
  defaultValue?: string
  className?: string
}

export const AutoComplete = <
  Values extends PyroFormValues,
  Suggestion extends AutoCompleteSuggestionProps,
  Filter extends keyof Suggestion,
>({
  suggestions: data,
  placeholder,
  name,
  filterBy,
  variant,
  defaultValue = '',
  className,
  label,
  ...props
}: Props<Values, Extract<keyof Values, string>, Suggestion, Filter>) => {
  const classes = useStyles()
  const variants = useVariant()

  const containerRef = useRef<HTMLDivElement>(null)
  const [searchTerm, setSearchTerm] = useState(defaultValue)
  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  // State for search status (whether there is a pending API request)
  const [isSearching, setIsSearching] = useState(false)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const throttledSearchTerm = useThrottle(searchTerm, 200)

  const {
    core: { onChange },
  } = usePyroField<Values, Extract<keyof Values, string>>(name)

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  // Effect for the Suggestion's fetch
  useEffect(() => {
    setSuggestions([])
    if (!searchTerm) {
      setShowSuggestions(false)
    }
    setIsSearching(true)
    // If the query term is short or ends with a
    // space, trigger the more impatient version.
    const onChangeValue = searchTerm.length < 5 || searchTerm.endsWith(' ') ? throttledSearchTerm : debouncedSearchTerm
    onChange(onChangeValue)
  }, [debouncedSearchTerm]) // Only call effect if debounced search term changes

  useEffect(() => {
    setSuggestions(data)
    setIsSearching(false)
  }, [data]) // Only call effect if debounced search term changes

  // it checks if the click was inside or outside of the AutoComplete's component
  const handleClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as HTMLDivElement)) {
      setShowSuggestions(false)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setSearchTerm(event.target.value)
    setShowSuggestions(true)
  }

  const handleClickSuggestion = (suggestionID: string) => {
    const selectedSuggestion = suggestions.find(({ id }) => id === suggestionID)
    if (selectedSuggestion) {
      const selectedSuggestionValue = String(selectedSuggestion[filterBy])
      setSearchTerm(selectedSuggestionValue)
      onChange(selectedSuggestionValue)
      setShowSuggestions(false)
    }
  }

  return (
    <div className={classNames(className, classes.container)} ref={containerRef}>
      <TextField
        label={label}
        name={name}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon={Icons.SEARCH} color="divider" size="large" />
            </InputAdornment>
          ),
          classes: {
            root: classNames(classes.inputRoot, variants[variant!]),
            input: classes.inputInput,
          },
        }}
        onChange={handleChange}
        onFocus={() => setShowSuggestions(true)}
        autoComplete="off"
        value={searchTerm}
        fullWidth
      />
      {showSuggestions && (
        <Paper className={classes.suggestions} square>
          <AutoCompleteSuggestions
            suggestions={suggestions}
            query={searchTerm}
            onClick={handleClickSuggestion}
            isSearching={isSearching}
            {...props}
          />
        </Paper>
      )}
    </div>
  )
}

AutoComplete.defaultProps = {
  variant: 'default',
}

export default AutoComplete
