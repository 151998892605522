/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: Date | string; output: string; }
  DateTime: { input: Date | string; output: string; }
};

export type CompanyContractInput = {
  bic?: InputMaybe<Scalars['String']['input']>;
  iban: Scalars['String']['input'];
  location: LocationInput;
  name: Scalars['String']['input'];
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type ContractInfo = {
  __typename: 'ContractInfo';
  contractStartDate: Scalars['Date']['output'];
  end: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  subscriptionDiscountAmount: Maybe<Scalars['Int']['output']>;
  subscriptionDiscountEnd: Maybe<Scalars['DateTime']['output']>;
  subscriptionDiscountStart: Maybe<Scalars['DateTime']['output']>;
  subscriptionFee: MonetaryValue;
  subscriptionPackage: Scalars['String']['output'];
  subscriptionPeriod: Scalars['String']['output'];
};

export type Country = {
  __typename: 'Country';
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateCompanyContractPdfResponse = CreateCompanyContractPdfResult | ExpectedErrors;

export type CreateCompanyContractPdfResult = {
  __typename: 'CreateCompanyContractPdfResult';
  url: Scalars['String']['output'];
};

export type CreateOnboardingCompanyResponse = CreateOnboardingCompanyResult | ExpectedErrors;

export type CreateOnboardingCompanyResult = {
  __typename: 'CreateOnboardingCompanyResult';
  success: Scalars['Boolean']['output'];
};

export type Error = {
  __typename: 'Error';
  /**
   * This represents a unique id for each type of error / validator. This id can be
   * used to create a translated message.
   */
  id: Scalars['String']['output'];
  /**
   * This can provide a translated error message for the specific error, for clients
   * that do not want to format errors on the client-side. Usage of this is discouraged.
   * Clients should really use the `id` and `params` to format error messages on the client.
   */
  message: Scalars['String']['output'];
  /**
   * For deeply nested input data, the error will provide the `path` to the invalid
   * property.
   *
   * For example in credentialLogin when the password is to short: `credentials.password`
   */
  path: Maybe<Scalars['String']['output']>;
};

/**
 * For our GraphQL API, we handle expected and unexpected errors differently.
 * For expected errors, each resolver will return a union type that includes the
 * `Errors` type as one of the variants.
 */
export type ExpectedErrors = {
  __typename: 'ExpectedErrors';
  errors: Array<Error>;
};

export type LocationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type MonetaryValue = {
  __typename: 'MonetaryValue';
  currency: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  /** This will create the URL to download the contract PDF */
  createCompanyContractPdf: CreateCompanyContractPdfResponse;
  /** This will create all data that is necessary for a company */
  createOnboardingCompany: CreateOnboardingCompanyResponse;
  /** This will validate the passed token and return relevant onboarding data */
  validateAndLoadByToken: ValidateAndLoadByTokenResponse;
  /** This will validate the passed IBAN */
  validateIban: ValidateIbanResponse;
  /** This will validate the passed vat number */
  validateVat: ValidateVatResponse;
};


export type MutationCreateCompanyContractPdfArgs = {
  company: CompanyContractInput;
  token: Scalars['String']['input'];
};


export type MutationCreateOnboardingCompanyArgs = {
  admin: OnboardingAdminInput;
  company: OnboardingCompanyInput;
  facility: OnboardingFacilityInput;
  signature: OnboardingSignatureInput;
  token: Scalars['String']['input'];
};


export type MutationValidateAndLoadByTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationValidateIbanArgs = {
  iban: Scalars['String']['input'];
};


export type MutationValidateVatArgs = {
  vat: Scalars['String']['input'];
};

export type OnboardingAdminInput = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type OnboardingCompanyInput = {
  bic?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  location: LocationInput;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingFacilityInput = {
  brandPromise: Scalars['String']['input'];
  brandPromiseText?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  name: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingSignatureInput = {
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type Query = {
  __typename: 'Query';
  /** This will return the contract information of the provided token */
  contractInfo: ContractInfo;
  /** This will return all countries */
  countries: Array<Country>;
  /** This will return all sports */
  sports: Array<Sport>;
};


export type QueryContractInfoArgs = {
  token: Scalars['String']['input'];
};

export type Sport = {
  __typename: 'Sport';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ValidateAndLoadByTokenResponse = ExpectedErrors | ValidateAndLoadByTokenResult;

export type ValidateAndLoadByTokenResult = {
  __typename: 'ValidateAndLoadByTokenResult';
  customerAdminMail: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
};

export type ValidateIbanResponse = ExpectedErrors | ValidateIbanResult;

export type ValidateIbanResult = {
  __typename: 'ValidateIbanResult';
  isValid: Scalars['Boolean']['output'];
};

export type ValidateVatResponse = ExpectedErrors | ValidateVatResult;

export type ValidateVatResult = {
  __typename: 'ValidateVatResult';
  isValid: Scalars['Boolean']['output'];
};

export type ValidateAndLoadByTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ValidateAndLoadByTokenMutation = { validateAndLoadByToken: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'Error', id: string, message: string, path: string | null }> } | { __typename: 'ValidateAndLoadByTokenResult', isValid: boolean, customerAdminMail: string | null } };

export type SelectCountryCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectCountryCountriesQuery = { countries: Array<{ __typename: 'Country', name: string, iso: string }> };

export type SelectStudioTypesSportsQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectStudioTypesSportsQuery = { sports: Array<{ __typename: 'Sport', id: string, name: string }> };

export type ValidateIbanMutationVariables = Exact<{
  iban: Scalars['String']['input'];
}>;


export type ValidateIbanMutation = { validateIban: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'Error', id: string, message: string, path: string | null }> } | { __typename: 'ValidateIbanResult', isValid: boolean } };

export type ValidateVatMutationVariables = Exact<{
  vat: Scalars['String']['input'];
}>;


export type ValidateVatMutation = { validateVat: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'Error', id: string, message: string, path: string | null }> } | { __typename: 'ValidateVatResult', isValid: boolean } };

export type CreateOnboardingCompanyMutationVariables = Exact<{
  token: Scalars['String']['input'];
  company: OnboardingCompanyInput;
  facility: OnboardingFacilityInput;
  admin: OnboardingAdminInput;
  signature: OnboardingSignatureInput;
}>;


export type CreateOnboardingCompanyMutation = { createOnboardingCompany: { __typename: 'CreateOnboardingCompanyResult', success: boolean } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'Error', id: string, message: string, path: string | null }> } };

export type RegistrationTermsAndConditionsDataContractInfoQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type RegistrationTermsAndConditionsDataContractInfoQuery = { contractInfo: { __typename: 'ContractInfo', name: string, start: string, end: string, subscriptionPackage: string, subscriptionPeriod: string, subscriptionDiscountAmount: number | null, subscriptionDiscountEnd: string | null, subscriptionDiscountStart: string | null, subscriptionFee: { __typename: 'MonetaryValue', value: number, currency: string } } };

export type CreateCompanyContractPdfMutationVariables = Exact<{
  token: Scalars['String']['input'];
  company: CompanyContractInput;
}>;


export type CreateCompanyContractPdfMutation = { createCompanyContractPdf: { __typename: 'CreateCompanyContractPdfResult', url: string } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'Error', id: string, message: string, path: string | null }> } };


export const ValidateAndLoadByTokenDocument = gql`
    mutation ValidateAndLoadByToken($token: String!) {
  validateAndLoadByToken(token: $token) {
    __typename
    ... on ValidateAndLoadByTokenResult {
      isValid
      customerAdminMail
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type ValidateAndLoadByTokenMutationFn = Apollo.MutationFunction<ValidateAndLoadByTokenMutation, ValidateAndLoadByTokenMutationVariables>;

/**
 * __useValidateAndLoadByTokenMutation__
 *
 * To run a mutation, you first call `useValidateAndLoadByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAndLoadByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAndLoadByTokenMutation, { data, loading, error }] = useValidateAndLoadByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateAndLoadByTokenMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAndLoadByTokenMutation, ValidateAndLoadByTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateAndLoadByTokenMutation, ValidateAndLoadByTokenMutationVariables>(ValidateAndLoadByTokenDocument, options);
      }
export type ValidateAndLoadByTokenMutationHookResult = ReturnType<typeof useValidateAndLoadByTokenMutation>;
export type ValidateAndLoadByTokenMutationResult = Apollo.MutationResult<ValidateAndLoadByTokenMutation>;
export type ValidateAndLoadByTokenMutationOptions = Apollo.BaseMutationOptions<ValidateAndLoadByTokenMutation, ValidateAndLoadByTokenMutationVariables>;
export const SelectCountryCountriesDocument = gql`
    query SelectCountryCountries {
  countries {
    name
    iso
  }
}
    `;

/**
 * __useSelectCountryCountriesQuery__
 *
 * To run a query within a React component, call `useSelectCountryCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectCountryCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectCountryCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectCountryCountriesQuery(baseOptions?: Apollo.QueryHookOptions<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>(SelectCountryCountriesDocument, options);
      }
export function useSelectCountryCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>(SelectCountryCountriesDocument, options);
        }
export function useSelectCountryCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>(SelectCountryCountriesDocument, options);
        }
export type SelectCountryCountriesQueryHookResult = ReturnType<typeof useSelectCountryCountriesQuery>;
export type SelectCountryCountriesLazyQueryHookResult = ReturnType<typeof useSelectCountryCountriesLazyQuery>;
export type SelectCountryCountriesSuspenseQueryHookResult = ReturnType<typeof useSelectCountryCountriesSuspenseQuery>;
export type SelectCountryCountriesQueryResult = Apollo.QueryResult<SelectCountryCountriesQuery, SelectCountryCountriesQueryVariables>;
export const SelectStudioTypesSportsDocument = gql`
    query SelectStudioTypesSports {
  sports {
    id
    name
  }
}
    `;

/**
 * __useSelectStudioTypesSportsQuery__
 *
 * To run a query within a React component, call `useSelectStudioTypesSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectStudioTypesSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectStudioTypesSportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectStudioTypesSportsQuery(baseOptions?: Apollo.QueryHookOptions<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>(SelectStudioTypesSportsDocument, options);
      }
export function useSelectStudioTypesSportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>(SelectStudioTypesSportsDocument, options);
        }
export function useSelectStudioTypesSportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>(SelectStudioTypesSportsDocument, options);
        }
export type SelectStudioTypesSportsQueryHookResult = ReturnType<typeof useSelectStudioTypesSportsQuery>;
export type SelectStudioTypesSportsLazyQueryHookResult = ReturnType<typeof useSelectStudioTypesSportsLazyQuery>;
export type SelectStudioTypesSportsSuspenseQueryHookResult = ReturnType<typeof useSelectStudioTypesSportsSuspenseQuery>;
export type SelectStudioTypesSportsQueryResult = Apollo.QueryResult<SelectStudioTypesSportsQuery, SelectStudioTypesSportsQueryVariables>;
export const ValidateIbanDocument = gql`
    mutation ValidateIban($iban: String!) {
  validateIban(iban: $iban) {
    __typename
    ... on ValidateIbanResult {
      isValid
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type ValidateIbanMutationFn = Apollo.MutationFunction<ValidateIbanMutation, ValidateIbanMutationVariables>;

/**
 * __useValidateIbanMutation__
 *
 * To run a mutation, you first call `useValidateIbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateIbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateIbanMutation, { data, loading, error }] = useValidateIbanMutation({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useValidateIbanMutation(baseOptions?: Apollo.MutationHookOptions<ValidateIbanMutation, ValidateIbanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateIbanMutation, ValidateIbanMutationVariables>(ValidateIbanDocument, options);
      }
export type ValidateIbanMutationHookResult = ReturnType<typeof useValidateIbanMutation>;
export type ValidateIbanMutationResult = Apollo.MutationResult<ValidateIbanMutation>;
export type ValidateIbanMutationOptions = Apollo.BaseMutationOptions<ValidateIbanMutation, ValidateIbanMutationVariables>;
export const ValidateVatDocument = gql`
    mutation ValidateVat($vat: String!) {
  validateVat(vat: $vat) {
    __typename
    ... on ValidateVatResult {
      isValid
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type ValidateVatMutationFn = Apollo.MutationFunction<ValidateVatMutation, ValidateVatMutationVariables>;

/**
 * __useValidateVatMutation__
 *
 * To run a mutation, you first call `useValidateVatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateVatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateVatMutation, { data, loading, error }] = useValidateVatMutation({
 *   variables: {
 *      vat: // value for 'vat'
 *   },
 * });
 */
export function useValidateVatMutation(baseOptions?: Apollo.MutationHookOptions<ValidateVatMutation, ValidateVatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateVatMutation, ValidateVatMutationVariables>(ValidateVatDocument, options);
      }
export type ValidateVatMutationHookResult = ReturnType<typeof useValidateVatMutation>;
export type ValidateVatMutationResult = Apollo.MutationResult<ValidateVatMutation>;
export type ValidateVatMutationOptions = Apollo.BaseMutationOptions<ValidateVatMutation, ValidateVatMutationVariables>;
export const CreateOnboardingCompanyDocument = gql`
    mutation CreateOnboardingCompany($token: String!, $company: OnboardingCompanyInput!, $facility: OnboardingFacilityInput!, $admin: OnboardingAdminInput!, $signature: OnboardingSignatureInput!) {
  createOnboardingCompany(
    token: $token
    company: $company
    facility: $facility
    admin: $admin
    signature: $signature
  ) {
    __typename
    ... on CreateOnboardingCompanyResult {
      success
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type CreateOnboardingCompanyMutationFn = Apollo.MutationFunction<CreateOnboardingCompanyMutation, CreateOnboardingCompanyMutationVariables>;

/**
 * __useCreateOnboardingCompanyMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingCompanyMutation, { data, loading, error }] = useCreateOnboardingCompanyMutation({
 *   variables: {
 *      token: // value for 'token'
 *      company: // value for 'company'
 *      facility: // value for 'facility'
 *      admin: // value for 'admin'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useCreateOnboardingCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnboardingCompanyMutation, CreateOnboardingCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnboardingCompanyMutation, CreateOnboardingCompanyMutationVariables>(CreateOnboardingCompanyDocument, options);
      }
export type CreateOnboardingCompanyMutationHookResult = ReturnType<typeof useCreateOnboardingCompanyMutation>;
export type CreateOnboardingCompanyMutationResult = Apollo.MutationResult<CreateOnboardingCompanyMutation>;
export type CreateOnboardingCompanyMutationOptions = Apollo.BaseMutationOptions<CreateOnboardingCompanyMutation, CreateOnboardingCompanyMutationVariables>;
export const RegistrationTermsAndConditionsDataContractInfoDocument = gql`
    query RegistrationTermsAndConditionsDataContractInfo($token: String!) {
  contractInfo(token: $token) {
    name
    start
    end
    subscriptionPackage
    subscriptionPeriod
    subscriptionFee {
      value
      currency
    }
    subscriptionDiscountAmount
    subscriptionDiscountEnd
    subscriptionDiscountStart
  }
}
    `;

/**
 * __useRegistrationTermsAndConditionsDataContractInfoQuery__
 *
 * To run a query within a React component, call `useRegistrationTermsAndConditionsDataContractInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationTermsAndConditionsDataContractInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationTermsAndConditionsDataContractInfoQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegistrationTermsAndConditionsDataContractInfoQuery(baseOptions: Apollo.QueryHookOptions<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables> & ({ variables: RegistrationTermsAndConditionsDataContractInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables>(RegistrationTermsAndConditionsDataContractInfoDocument, options);
      }
export function useRegistrationTermsAndConditionsDataContractInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables>(RegistrationTermsAndConditionsDataContractInfoDocument, options);
        }
export function useRegistrationTermsAndConditionsDataContractInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables>(RegistrationTermsAndConditionsDataContractInfoDocument, options);
        }
export type RegistrationTermsAndConditionsDataContractInfoQueryHookResult = ReturnType<typeof useRegistrationTermsAndConditionsDataContractInfoQuery>;
export type RegistrationTermsAndConditionsDataContractInfoLazyQueryHookResult = ReturnType<typeof useRegistrationTermsAndConditionsDataContractInfoLazyQuery>;
export type RegistrationTermsAndConditionsDataContractInfoSuspenseQueryHookResult = ReturnType<typeof useRegistrationTermsAndConditionsDataContractInfoSuspenseQuery>;
export type RegistrationTermsAndConditionsDataContractInfoQueryResult = Apollo.QueryResult<RegistrationTermsAndConditionsDataContractInfoQuery, RegistrationTermsAndConditionsDataContractInfoQueryVariables>;
export const CreateCompanyContractPdfDocument = gql`
    mutation CreateCompanyContractPdf($token: String!, $company: CompanyContractInput!) {
  createCompanyContractPdf(token: $token, company: $company) {
    __typename
    ... on CreateCompanyContractPdfResult {
      url
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type CreateCompanyContractPdfMutationFn = Apollo.MutationFunction<CreateCompanyContractPdfMutation, CreateCompanyContractPdfMutationVariables>;

/**
 * __useCreateCompanyContractPdfMutation__
 *
 * To run a mutation, you first call `useCreateCompanyContractPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyContractPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyContractPdfMutation, { data, loading, error }] = useCreateCompanyContractPdfMutation({
 *   variables: {
 *      token: // value for 'token'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateCompanyContractPdfMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyContractPdfMutation, CreateCompanyContractPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyContractPdfMutation, CreateCompanyContractPdfMutationVariables>(CreateCompanyContractPdfDocument, options);
      }
export type CreateCompanyContractPdfMutationHookResult = ReturnType<typeof useCreateCompanyContractPdfMutation>;
export type CreateCompanyContractPdfMutationResult = Apollo.MutationResult<CreateCompanyContractPdfMutation>;
export type CreateCompanyContractPdfMutationOptions = Apollo.BaseMutationOptions<CreateCompanyContractPdfMutation, CreateCompanyContractPdfMutationVariables>;