import Baseline from '@eversports/react-components/design-tokens/Baseline'
import GoogleRecaptchaAPI from '@eversports/react-components/logical/GoogleRecaptchaAPI'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router'

import AppContentLayout from '../components/AppContentLayout'
import ResetFontCSS from '../components/ResetFontCSS'
import { getSharedConfig } from '../get-config'
import { useIntl } from '../localization/react'
import NotFound from '../pages/NotFound'
import { RootRoutes } from '../routes'

import FreeTrialContextProvider from './FreeTrialContextProvider'
import FreeTrialRoutes from './FreeTrialRoutes'
import RegistrationContextProvider from './RegistrationContextProvider'
import RegistrationRoutes from './RegistrationRoutes'

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  return (
    <>
      <Helmet>
        <title>{intl.appTitle()}</title>
        {typeof window !== 'undefined' && (
          <script
            type="text/javascript"
            src={`https://maps.googleapis.com/maps/api/js?key=${
              getSharedConfig().GOOGLE_MAPS_API_KEY
            }&libraries=places`}
          />
        )}
      </Helmet>
      <Baseline />
      <ResetFontCSS />
      <AppContentLayout>
        <Switch>
          <Route
            path={RootRoutes.FREE_TRIAL}
            render={() => (
              <FreeTrialContextProvider>
                <GoogleRecaptchaAPI language={intl.context.locale.loaded}>
                  <FreeTrialRoutes />
                </GoogleRecaptchaAPI>
              </FreeTrialContextProvider>
            )}
          />
          <Route
            path={RootRoutes.REGISTRATION}
            render={() => (
              <RegistrationContextProvider>
                <RegistrationRoutes />
              </RegistrationContextProvider>
            )}
          />
          <Route render={() => <NotFound />} />
        </Switch>
      </AppContentLayout>
    </>
  )
}

export default App
