import {
  Intl as IntlError,
  loadLanguage as loadLanguageError,
  Provider as LocalizationProviderError,
} from '@eversports/core-io/localization/react'
import {
  Intl as IntlComponents,
  loadLanguage as loadLanguageComponents,
  Provider as LocalizationProviderComponents,
} from '@eversports/react-components/localization/react'
import React from 'react'

import {
  Intl as IntlSelfOnBoarding,
  loadLanguage as loadLanguageSelfOnBoarding,
  Locales,
  Provider as LocalizationProviderSelfOnBoarding,
} from './localization/react'

interface Props {
  intlSelfOnBoarding: IntlSelfOnBoarding
  intlError: IntlError
  intlComponents: IntlComponents
}

const SelfOnBoardingLocalizationProvider: React.FC<React.PropsWithChildren<Props>> = ({
  intlSelfOnBoarding,
  intlError,
  intlComponents,
  children,
}) => (
  <LocalizationProviderSelfOnBoarding value={intlSelfOnBoarding}>
    <LocalizationProviderError value={intlError}>
      <LocalizationProviderComponents value={intlComponents}>{children}</LocalizationProviderComponents>
    </LocalizationProviderError>
  </LocalizationProviderSelfOnBoarding>
)

async function getLocalizationProvider(locale: Locales): Promise<React.FC<React.PropsWithChildren<unknown>>> {
  const [intlSelfOnBoarding, intlError, intlComponents] = await Promise.all([
    loadLanguageSelfOnBoarding(locale),
    loadLanguageError(locale),
    loadLanguageComponents(locale),
  ])

  // eslint-disable-next-line react/display-name
  return (props) => (
    <SelfOnBoardingLocalizationProvider
      intlSelfOnBoarding={intlSelfOnBoarding}
      intlError={intlError}
      intlComponents={intlComponents}
      {...props}
    />
  )
}

export default getLocalizationProvider
