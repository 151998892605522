import React, { useState } from 'react'

import FreeTrialContext, { FreeTrialCreateAnAdminUserProps, FreeTrialVenueProps } from '../free-trial-context'

const FreeTrialContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // Venue Values
  const [venueValues, setVenueValues] = useState<FreeTrialVenueProps>({
    companyName: '',
    studioTypes: [],
    country: '',
    website: '',
  })

  // Create An Admin User Values
  const [createAnAdminUserValues, setCreateAnAdminUserValues] = useState<FreeTrialCreateAnAdminUserProps>({
    firstName: '',
    lastName: '',
    countryCallingCode: '+43',
    phoneNumber: '',
    email: '',
    password: '',
    question: '',
  })

  return (
    <FreeTrialContext.Provider
      value={{
        venueValues,
        setVenueValues,
        createAnAdminUserValues,
        setCreateAnAdminUserValues,
      }}
    >
      {children}
    </FreeTrialContext.Provider>
  )
}

export default FreeTrialContextProvider
