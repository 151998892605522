import { default as MuiSelect, SelectProps } from '@mui/material/Select'
import * as React from 'react'

interface Props extends Omit<SelectProps, 'MenuProps'> {
  className?: string
}

const SelectSingle: React.FC<React.PropsWithChildren<Props>> = (props) => <MuiSelect {...props} />

export default SelectSingle
