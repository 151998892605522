import { Query } from '@apollo/client/react/components'
import MonetaryValue from '@eversports/react-components/Legacy/MonetaryValue'
import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import Flex from '@eversports/react-components/primitives/Flex'
import FormatDate from '@eversports/react-components/primitives/FormatDate'
import Placeholder from '@eversports/react-components/primitives/Placeholder'
import { parse } from 'query-string'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  RegistrationTermsAndConditionsDataContractInfoDocument,
  RegistrationTermsAndConditionsDataContractInfoQuery,
} from '../../graphql'

import { Localized } from '../../localization/react'

import RegistrationTermsAndConditionsDataSubject from './RegistrationTermsAndConditionsDataSubject'

const RegistrationTermsAndConditionsData: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ location }) => {
  const { token } = parse(location.search)

  if (!token) {
    throw new Error('The required token was not defined')
  }

  return (
    <Query<RegistrationTermsAndConditionsDataContractInfoQuery>
      query={RegistrationTermsAndConditionsDataContractInfoDocument}
      variables={{ token }}
    >
      {({ error, data }) => {
        if (error) {
          return <>{error.message}</>
        }

        if (data) {
          const {
            start,
            end,
            subscriptionPackage,
            subscriptionPeriod,
            subscriptionFee: { value, currency },
            subscriptionDiscountStart,
            subscriptionDiscountEnd,
            subscriptionDiscountAmount,
          } = data.contractInfo
          return (
            <Flex flexDirection="column" verticalSpacing="dense" maxWidth="368px" fullWidth>
              <RegistrationTermsAndConditionsDataSubject
                subject={<Localized id="registration-terms-and-conditions-package" />}
                value={<Localized id={`billing-pricing-option-${subscriptionPackage.toLowerCase()}` as any} />}
              />
              <RegistrationTermsAndConditionsDataSubject
                subject={<Localized id="registration-terms-and-conditions-contract-start" />}
                value={<FormatDate variant="medium" value={start} />}
              />
              <RegistrationTermsAndConditionsDataSubject
                subject={<Localized id="registration-terms-and-conditions-contract-end" />}
                value={<FormatDate variant="medium" value={end} />}
              />
              {value > 0 && (
                <RegistrationTermsAndConditionsDataSubject
                  subject={<Localized id="registration-terms-and-conditions-rate" />}
                  value={
                    <Localized
                      id="registration-terms-and-conditions-per"
                      params={{
                        charge: <MonetaryValue value={value} currencyCode={currency} />,
                        period: subscriptionPeriod,
                      }}
                    />
                  }
                />
              )}
              {subscriptionDiscountAmount && subscriptionDiscountAmount > 0 && (
                <RegistrationTermsAndConditionsDataSubject
                  subject={<Localized id="registration-terms-and-conditions-discount" />}
                  value={
                    <Localized
                      id="registration-terms-and-conditions-discount-value"
                      params={{
                        money: (
                          <MonetaryValue
                            value={value * (1 - subscriptionDiscountAmount / 100)}
                            currencyCode={currency}
                          />
                        ),
                        discount: subscriptionDiscountAmount,
                        start: subscriptionDiscountStart && (
                          <FormatDate variant="medium" value={subscriptionDiscountStart} />
                        ),
                        end: subscriptionDiscountEnd && <FormatDate variant="medium" value={subscriptionDiscountEnd} />,
                      }}
                    />
                  }
                />
              )}
            </Flex>
          )
        }

        return (
          <Placeholder height={250} width="100%" center>
            <CircularProgress />
          </Placeholder>
        )
      }}
    </Query>
  )
}
export default withRouter(RegistrationTermsAndConditionsData)
