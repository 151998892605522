// DO NOT USE THIS
// This is just for experimentation purposes and not stable yet so do not use it!!!

interface Success<T> {
  isError: false
  value: T
}

interface Failure<E extends Error> {
  isError: true
  error: E
}

export type Result<T, E extends Error = Error> = Success<T> | Failure<E>

export type AsyncResult<T, E extends Error = Error> = Promise<Result<T, E>>

export function success<T>(value: T): Success<T> {
  return {
    isError: false,
    value,
  }
}

export function failure<E extends Error>(error: E): Failure<E> {
  return {
    isError: true,
    error,
  }
}
