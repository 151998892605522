import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { ReactNode } from 'react'

import MenuItem from '../MenuItem'

export interface Props {
  children: ReactNode
  onClick: () => void
}

const useStyles = makeStyles({
  menuItem: {
    height: 'auto',
  },
})

const AutoCompleteSuggestion: React.FC<React.PropsWithChildren<Props>> = ({ children, onClick }) => {
  const classes = useStyles()
  return (
    <MenuItem className={classes.menuItem} onClick={onClick}>
      {children}
    </MenuItem>
  )
}

export default AutoCompleteSuggestion
