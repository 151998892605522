import { PyroFormValues, usePyroField } from 'pyro-form'
import * as React from 'react'

import { MultiSelectValue } from './SelectMultiple'
import SelectWrapper, { SelectRef } from './SelectWrapper'

interface SelectProps {
  name: string
  className?: string
  required?: boolean
  multiple?: boolean
  fullWidth?: boolean
  disabled?: boolean
  value?: string | number | Array<string | number>
}

export interface SelectPrimitiveProps extends React.PropsWithChildren<SelectProps> {
  label?: string | React.ReactElement
}

interface Props<Values extends PyroFormValues> extends SelectPrimitiveProps {
  name: Extract<keyof Values, string>
  multipleSelectRenderValues?: Array<MultiSelectValue>
}

// eslint-disable-next-line no-shadow
export const Select = React.forwardRef(function Select<Values extends PyroFormValues>(
  { name, ...muiProps }: Props<Values>,
  ref?: React.Ref<SelectRef>,
) {
  const fieldProps = usePyroField<Values, Extract<keyof Values, string>>(name)
  return <SelectWrapper {...fieldProps} {...muiProps} name={name} ref={ref} />
})

export default Select
