import { IOError } from '@eversports/io-error'
import { convertIdentifier, IntlObject } from 'intl-codegen/runtime'
import * as React from 'react'

import { Localized } from './localization/react'

export function mapIOErrorToLocalizedObject(error: IOError, intl?: IntlObject<any, any>) {
  return error.errors.reduce((previousValue, { path, id, params, fieldNameId }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const fieldNameFn = intl && intl[convertIdentifier(String(fieldNameId))]
    return {
      ...previousValue,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      [path[0]]: <Localized id={id as any} params={{ field: fieldNameFn ? fieldNameFn() : 'undefined', ...params }} />,
    }
  }, {})
}
