import { useApolloClient } from '@apollo/client'
import { mapIOErrorToLocalizedObject } from '@eversports/core-io/helpers'
import { isIOError } from '@eversports/io-error'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import TextField from '@eversports/react-components/primitives/TextField'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import PyroForm, { Form } from 'pyro-form'
import { parse } from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import StepperActions from '../../components/StepperActions'
import { Localized, useIntl } from '../../localization/react'
import RegistrationContext, { RegistrationTermsAndConditionsProps } from '../../registration-context'
import { RegistrationRoutes } from '../../routes'

import check from './check'
import createCompanyContractPDF from './create-company-contract-pdf'
import getDataProcessingAgreementURL from './get-data-processing-agreement-url'
import RegistrationTermsAndConditionsData from './RegistrationTermsAndConditionsData'
import RegistrationTermsAndConditionsDescription from './RegistrationTermsAndConditionsDescription'

const RegistrationTermsAndConditions: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({
  location,
  history,
}) => {
  const { token } = parse(location.search)
  const intl = useIntl()
  const registrationContext = useContext(RegistrationContext)
  const [companyContractPdfURL, setCompanyContractPdfURL] = useState('')
  const client = useApolloClient()

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  if (!token) {
    throw new Error('The required token was not defined')
  }

  useEffect(() => {
    makeCompanyContractPDF().catch((error) => {
      ClientLogger.error('Make Company Contract PDF  Failed', error)
    })
  }, [])

  const makeCompanyContractPDF = async () => {
    const result = await createCompanyContractPDF(client)({
      company: {
        ...registrationContext.billingAndFinancialInformation,
        name: registrationContext.legalCompanyInformation.name,
        location: {
          street: registrationContext.legalCompanyInformation.street,
          streetNumber: registrationContext.legalCompanyInformation.streetNumber,
          city: registrationContext.legalCompanyInformation.city,
          zip: registrationContext.legalCompanyInformation.zip,
          country: registrationContext.legalCompanyInformation.country,
          longitude: registrationContext.legalCompanyInformation.longitude,
          latitude: registrationContext.legalCompanyInformation.latitude,
        },
      },
      token: String(token),
    })

    if (result.isError) {
      // TODO: Think how to handle this error
      ClientLogger.error('Make Company Contract PDF  Failed', result.error)
    } else if (result.value.__typename === 'CreateCompanyContractPdfResult') {
      setCompanyContractPdfURL(result.value.url)
    }
  }

  const handleValidate = throttledPyroFormValidator((values: RegistrationTermsAndConditionsProps) => {
    try {
      check(values)
    } catch (e: any) {
      if (isIOError(e)) {
        return mapIOErrorToLocalizedObject(e, intl)
      }

      ClientLogger.error('Terms and conditions validation failed', e)
    }

    return {}
  })

  const initialValues = {
    userAgreedToAllTermsAndConditions: [],
    firstname: registrationContext.userAdminInformation.firstname,
    lastname: registrationContext.userAdminInformation.lastname,
    role: '',
  }

  const handleForwardStep = (values: typeof initialValues) => {
    const { userAgreedToAllTermsAndConditions, ...termsAndConditionsInformation } = values
    registrationContext.setTermsAndConditionsInformation(termsAndConditionsInformation)
    registrationContext.setValidStep(RegistrationRoutes.TERMS_AND_CONDITIONS)
    history.push(`${RegistrationRoutes.COMPLETE_SET_UP}${location.search}`)
  }

  const handleBackStep = () => {
    history.push(`${RegistrationRoutes.CREATE_AN_ADMIN_USER}${location.search}`)
  }

  return (
    <Flex flexDirection="column" verticalSpacing="wide">
      <Heading is="h2">
        <Localized id="registration-terms-and-conditions-heading" />
      </Heading>

      <RegistrationTermsAndConditionsData />

      <PyroForm initialValues={initialValues} onValidate={handleValidate} onSubmit={handleForwardStep}>
        {({ hasErrors }) => (
          <Form>
            <Flex flexDirection="column" verticalSpacing="wide">
              <RegistrationTermsAndConditionsDescription
                dataProcessingAgreementURL={getDataProcessingAgreementURL(intl.context.locale.loaded)}
                companyContractPdfURL={companyContractPdfURL}
              />
              <Flex horizontalSpacing="default" fullWidth>
                <TextField name="firstname" label={<Localized id="user-first-name" />} fullWidth />
                <TextField name="lastname" label={<Localized id="user-last-name" />} fullWidth />
              </Flex>
              <TextField name="role" label={<Localized id="registration-terms-and-conditions-role" />} fullWidth />
              <StepperActions
                isForwardActionDisabled={hasErrors || !companyContractPdfURL}
                onGoBack={handleBackStep}
                isCompletionStep
              />
            </Flex>
          </Form>
        )}
      </PyroForm>
    </Flex>
  )
}

export default withRouter(RegistrationTermsAndConditions)
