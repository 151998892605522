import { ApolloClient } from '@apollo/client'
import { AsyncResult, failure, success } from '../../result'
import {
  CreateOnboardingCompanyDocument,
  CreateOnboardingCompanyMutation,
  CreateOnboardingCompanyMutationVariables,
} from '../../graphql'

const createOnboardingCompany =
  (client: ApolloClient<unknown>) =>
  async (
    variables: CreateOnboardingCompanyMutationVariables,
  ): AsyncResult<CreateOnboardingCompanyMutation['createOnboardingCompany']> => {
    if (!variables.company || !variables.facility || !variables.admin || !variables.signature || !variables.token) {
      return failure(new Error('The required information from the previous steps were not filled out'))
    }

    const result = await client.mutate<CreateOnboardingCompanyMutation, CreateOnboardingCompanyMutationVariables>({
      mutation: CreateOnboardingCompanyDocument,
      variables,
    })

    if (!result.data || !result.data.createOnboardingCompany) {
      return failure(new Error('No data is being returned from mutation'))
    }

    if (result.data.createOnboardingCompany.__typename === 'ExpectedErrors') {
      // TODO: Implement some error handling. No idea how
      return failure(
        new Error('An expected error happened: ' + JSON.stringify(result.data.createOnboardingCompany.errors)),
      )
    }

    return success(result.data.createOnboardingCompany)
  }

export default createOnboardingCompany
