import combine from '@eversports/core-io/combine'
import * as s from '@eversports/core-io/sanitizer'
import * as v from '@eversports/core-io/validator'

export default combine({
  sanitizer: s.sanitizer({
    firstname: s.string(),
    lastname: s.string(),
    email: s.string(),
    password: s.string(),
  }),
  validator: v.validator({
    firstname: v.length({ min: 2 }),
    lastname: v.length({ min: 2 }),
    email: v.email(),
    password: v.password(),
  }),
  fieldNameIds: {
    firstname: 'user-first-name',
    lastname: 'user-last-name',
    email: 'user-email',
    password: 'password',
  },
})
