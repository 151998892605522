import { HorizontalFlex } from '@eversports/react-components/primitives/Flex'
import TextField from '@eversports/react-components/primitives/TextField'
import React from 'react'

import { Localized } from '../../localization/react'

import PhoneNumberFieldCountryCallingCodes from './PhoneNumberFieldCountryCallingCodes'

const PhoneNumberField: React.FC<React.PropsWithChildren<unknown>> = () => (
  <HorizontalFlex horizontalSpacing="dense">
    <div>
      <PhoneNumberFieldCountryCallingCodes />
    </div>
    <TextField type="phone" name="phoneNumber" label={<Localized id="phone-number" />} fullWidth />
  </HorizontalFlex>
)

export default PhoneNumberField
