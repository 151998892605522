import * as React from 'react'
import { useState } from 'react'

import useScript from '../useScript'

import getContextValue from './get-context-value'
import GoogleRecaptchaAPIContext from './GoogleRecaptchaAPIContext'

interface Props {
  language: string
}

const GoogleRecaptchaAPI: React.FC<React.PropsWithChildren<Props>> = ({ children, language }) => {
  const [isGoogleRecaptchaAPIReady, setIsGoogleRecaptchaAPIReady] = useState(false)
  const [GoogleRecaptchaAPILoaded, GoogleRecaptchaAPIHasError] = useScript({
    src: `https://www.google.com/recaptcha/api.js?hl=${language}&onload=googleRecaptchaInit`,
    initialize: () => {
      ;(window as unknown as { googleRecaptchaInit: () => void }).googleRecaptchaInit = () => {
        setIsGoogleRecaptchaAPIReady(true)
      }
    },
  })

  return (
    <GoogleRecaptchaAPIContext.Provider
      value={getContextValue({
        loaded: GoogleRecaptchaAPILoaded && isGoogleRecaptchaAPIReady,
        error: GoogleRecaptchaAPIHasError,
      })}
    >
      {children}
    </GoogleRecaptchaAPIContext.Provider>
  )
}

export default GoogleRecaptchaAPI
