import { useContext } from 'react'

import { GoogleRecaptchaAPIContext } from '../GoogleRecaptchaAPI'

const useGoogleRecaptchaAPI = () => {
  const context = useContext(GoogleRecaptchaAPIContext)
  return context
}

export default useGoogleRecaptchaAPI
