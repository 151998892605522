/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CreateCompanyContractPdfResponse": [
      "CreateCompanyContractPdfResult",
      "ExpectedErrors"
    ],
    "CreateOnboardingCompanyResponse": [
      "CreateOnboardingCompanyResult",
      "ExpectedErrors"
    ],
    "ValidateAndLoadByTokenResponse": [
      "ExpectedErrors",
      "ValidateAndLoadByTokenResult"
    ],
    "ValidateIbanResponse": [
      "ExpectedErrors",
      "ValidateIbanResult"
    ],
    "ValidateVatResponse": [
      "ExpectedErrors",
      "ValidateVatResult"
    ]
  }
};
      export default result;
    