import { mapIOErrorToLocalizedObject } from '@eversports/core-io/helpers'
import { isIOError } from '@eversports/io-error'
import { VerticalFlex } from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import PyroForm, { Form } from 'pyro-form'
import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import Address from '../../components/Address'
import PhoneNumberField from '../../components/PhoneNumberField'
import StepperActions from '../../components/StepperActions'
import { Localized, useIntl } from '../../localization/react'
import RegistrationContext, { RegistrationLegalCompanyProps } from '../../registration-context'
import { RegistrationRoutes } from '../../routes'

import check from './check'
import initialValues from './initial-values'

const RegistrationLegalCompany: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history }) => {
  const intl = useIntl()
  const registrationContext = useContext(RegistrationContext)
  const [errors, setErrors] = useState({})

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const handleValidate = throttledPyroFormValidator((values: RegistrationLegalCompanyProps) => {
    try {
      checkAddress(values)
      check(values)
    } catch (e: any) {
      if (isIOError(e)) {
        return mapIOErrorToLocalizedObject(e, intl)
      }
      ClientLogger.error('Company address validation failed', e)
    }
    return {}
  })

  const checkAddress = (values: RegistrationLegalCompanyProps) => {
    const error: { address?: string } = {}
    if (!values.street || !values.streetNumber || !values.zip || !values.city || !values.country) {
      error.address = intl.invalidAddress()
    }
    setErrors(error)
  }

  const handleForwardStep = (values: RegistrationLegalCompanyProps) => {
    registrationContext.setLegalCompanyInformation(values)
    registrationContext.setValidStep(RegistrationRoutes.LEGAL_COMPANY)
    history.push(`${RegistrationRoutes.BILLING_AND_FINANCIAL}${window.location.search}`)
  }

  const handleBackStep = () => {
    history.push(`${RegistrationRoutes.VENUE}${window.location.search}`)
  }

  return (
    <VerticalFlex verticalSpacing="wide">
      <VerticalFlex verticalSpacing="dense">
        <Heading is="h2">
          <Localized id="registration-legal-company-heading" />
        </Heading>
        <Text color="subtleText">
          <Localized id="registration-legal-company-subheading" />
        </Text>
      </VerticalFlex>
      <PyroForm
        initialValues={initialValues(registrationContext)}
        onValidate={handleValidate}
        onSubmit={handleForwardStep}
        errors={errors}
      >
        {({ values, hasErrors, handleChange }) => (
          <Form>
            <VerticalFlex verticalSpacing="wide">
              <TextField name="name" label={<Localized id="company-name" />} fullWidth autoFocus />
              <Address
                autoCompleteLabel={<Localized id="registration-legal-company-address" />}
                isAutoComplete={values.isAutoCompleteAddress}
                autoCompleteSearchValue={values.address}
                onChange={handleChange}
              />
              <TextField name="email" label={<Localized id="registration-legal-company-contact-email" />} fullWidth />
              <PhoneNumberField />
              <StepperActions isForwardActionDisabled={hasErrors} onGoBack={handleBackStep} />
            </VerticalFlex>
          </Form>
        )}
      </PyroForm>
    </VerticalFlex>
  )
}

export default withRouter(RegistrationLegalCompany)
