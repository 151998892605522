import { IOError } from '@eversports/io-error'

import {
  CombineFn,
  Sanitizer,
  SanitizerFn,
  SanitizerObj,
  SanitizerOut,
  Validator,
  ValidatorFn,
  ValidatorObj,
} from './types'

export const combine: CombineFn =
  (args) =>
  // NOTE(swatinem): so we have all this fancy type magic with nominal marker types
  // etc, but those are just hacks really to make meta-programming API for the
  // IO combinators easy to use and have good error messages there. But it means
  // that the actually implementation needs to resort to `any` in a lot of places.
  (input) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
    processInput(args as any, input) as any

export default combine

interface CombinedInput<T> {
  sanitizer: Sanitizer<T>
  validator?: Validator<T>
  fieldNameIds?: { [key: string]: string }
}

function processInput<T>(args: CombinedInput<T>, input: unknown) {
  if (typeof args.sanitizer === 'function') {
    return processValue(args as CombinedFn<unknown>, input)
  }
  if (Array.isArray(args.sanitizer)) {
    const sanitizer: any = args.sanitizer[0]
    const validator: any = args.validator && (Array.isArray(args.validator) ? args.validator[0] : args.validator)
    return processArray({ sanitizer, validator }, input)
  }
  return processObject(args as CombinedObj, input)
}

interface CombinedFn<T> {
  sanitizer: SanitizerFn<T>
  validator?: ValidatorFn<T>
}

function processValue<T>(args: CombinedFn<T>, input: unknown) {
  const { sanitizer, validator } = args
  let intermediate: T = sanitizer(input)
  if (intermediate === undefined) {
    return intermediate
  }
  if (validator) {
    intermediate = validator(intermediate)
  }

  return intermediate
}

interface CombinedObj {
  sanitizer: SanitizerObj
  validator?: ValidatorObj
  fieldNameIds?: { [key: string]: string }
}

const InvalidObject = IOError.factory('invalid-object')
function processObject(args: CombinedObj, input: unknown) {
  if (!input || typeof input !== 'object') {
    throw InvalidObject(input)
  }
  const { sanitizer, validator } = args
  const keys = Object.keys(sanitizer)
  const inputObj = input as any
  // @ts-expect-error Type instantiation is excessively deep and possibly infinite.
  const outputObj: SanitizerOut<(typeof args)['sanitizer']> = {}
  const errors = new IOError()
  for (const key of keys) {
    const processor = { sanitizer: sanitizer[key], validator: validator && validator[key] }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      outputObj[key] = processInput(processor, inputObj[key])
    } catch (e) {
      errors.addChildErrors(key, e as IOError, args.fieldNameIds)
    }
  }
  if (errors.errors.length) {
    throw errors
  }

  return outputObj
}

const InvalidArray = IOError.factory('invalid-array')
function processArray<T>(args: CombinedFn<T>, input: unknown): Array<T> {
  if (!Array.isArray(input)) {
    throw InvalidArray(input)
  }

  const inputArr = input as Array<unknown>
  const outputArr: Array<T> = []
  const errors = new IOError()

  // See the special case for `array`
  if (args.validator && args.validator.validator) {
    // check the `array` validator itself
    try {
      args.validator(inputArr as T)
    } catch (e) {
      errors.addChildErrors('', e as IOError)
    }
    args.validator = args.validator.validator
  }

  for (const [index, inputValue] of inputArr.entries()) {
    try {
      const value = processInput(args, inputValue)
      outputArr.push(value as T)
    } catch (e: any) {
      errors.addChildErrors(String(index), e as IOError)
    }
  }
  if (errors.errors.length) {
    throw errors
  }

  return outputArr
}
