import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { SelectProps } from '@mui/material/Select'
import { SelectChangeEvent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PyroFieldInnerRenderProps } from 'pyro-form'
import * as React from 'react'

import { Localized } from '../../localization/react'
import MenuItem from '../MenuItem'

import { SelectPrimitiveProps } from './Select'
import SelectMultiple, { MultiSelectValue } from './SelectMultiple'
import SelectSingle from './SelectSingle'

// The root element of MUI's FormControl is 'div' and we don't allow the change of this prop
export type SelectRef = HTMLElementTagNameMap['div']
type ComponentType = Omit<SelectProps, 'MenuProps' | 'variant'> & {
  multipleSelectRenderValues?: Array<MultiSelectValue>
}

interface Props extends PyroFieldInnerRenderProps, SelectPrimitiveProps {
  ref?: React.Ref<SelectRef>
  multipleSelectRenderValues?: Array<MultiSelectValue>
}

const useStyles = makeStyles({
  root: {
    height: 48,
  },
})

const SelectWrapper = ({
  label,
  fullWidth,
  name,
  children,
  core: { onChange, value },
  required,
  multiple,
  ref,
  multipleSelectRenderValues,
  ...props
}: Props) => {
  const classes = useStyles()
  const inputLabelRef = React.useRef(null)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value: eventValue } = event.target
    onChange(eventValue)
  }

  const componentProps: ComponentType = {
    ...props,
    children,
    value,
    onChange: handleChange,
    className: classNames(classes.root),
    input: <OutlinedInput label={label} name={name} notched />,
    displayEmpty: true,
    fullWidth,
  }

  if (multiple && multipleSelectRenderValues) {
    componentProps.multipleSelectRenderValues = multipleSelectRenderValues
  }

  const Component = (!multiple ? SelectSingle : SelectMultiple) as React.ComponentType<
    React.PropsWithChildren<ComponentType>
  >

  return (
    <FormControl variant="outlined" fullWidth={fullWidth} ref={ref}>
      {label && (
        <InputLabel ref={inputLabelRef} htmlFor={name} shrink>
          {label}
        </InputLabel>
      )}
      <Component {...componentProps}>
        {!required && !multiple && (
          <MenuItem value="">
            <Localized id="no-option" />
          </MenuItem>
        )}
        {children}
      </Component>
    </FormControl>
  )
}

export default SelectWrapper
