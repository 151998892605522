import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  content: {
    maxWidth: 600,
    margin: '0 auto',
  },
})

const MatchedFreeTrialRouteLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles()
  return (
    <Flex
      className={classes.content}
      justifyContent="flex-start"
      padding="default"
      paddingBottom="wide"
      verticalSpacing="wide"
      fullWidth
    >
      {children}
    </Flex>
  )
}

export default MatchedFreeTrialRouteLayout
