/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { defineLoader } from "intl-codegen/runtime";

export const loadLanguage = defineLoader([
  "addressAutocompleteLabel",
  "addressCity",
  "addressCountry",
  "addressNumber",
  "addressStreet",
  "addressZip",
  "appTitle",
  "back",
  "billingPricingOptionAccelerate",
  "billingPricingOptionChampion",
  "billingPricingOptionGrowth",
  "billingPricingOptionProfessional",
  "billingPricingOptionStarter",
  "clickHere",
  "clickHereToAnableAutocompleteAddress",
  "clickHereToFillAddressManually",
  "companyName",
  "countryCallingCode",
  "dateUnitDay",
  "dateUnitDayPlural",
  "dateUnitGame",
  "dateUnitGamePlural",
  "dateUnitHour",
  "dateUnitHourPlural",
  "dateUnitMinute",
  "dateUnitMinutePlural",
  "dateUnitMonth",
  "dateUnitMonthPlural",
  "dateUnitSeason",
  "dateUnitSeasonPlural",
  "dateUnitUnit",
  "dateUnitUnitPlural",
  "dateUnitWeek",
  "dateUnitWeekPlural",
  "dateUnitWeekend",
  "dateUnitWeekendPlural",
  "dateUnitYear",
  "dateUnitYearPlural",
  "eversports",
  "freeTrialCompleteSetUpGetInTouch",
  "freeTrialCompleteSetUpGetInTouchEmail",
  "freeTrialCompleteSetUpHeading",
  "freeTrialCompleteSetUpResendEmail",
  "freeTrialCompleteSetUpSubheadingFirstParagraph",
  "freeTrialCompleteSetUpSubheadingSecondParagraph",
  "freeTrialCompleteSetUpTakingTooLong",
  "freeTrialCreateAnAdminUserEmailInfo",
  "freeTrialCreateAnAdminUserQuestion",
  "freeTrialSubheading",
  "freeTrialTitle",
  "invalidAddress",
  "invalidBrandPromise",
  "invalidIban",
  "invalidUid",
  "invalidUrlHeading",
  "invalidUrlSubheading",
  "next",
  "no",
  "optional",
  "password",
  "passwordInfo",
  "paymentUnitEuro",
  "phoneNumber",
  "registrationBillingAndFinancialBic",
  "registrationBillingAndFinancialHeading",
  "registrationBillingAndFinancialIban",
  "registrationBillingAndFinancialSubheadingFirstParagraph",
  "registrationBillingAndFinancialSubheadingSecondParagraph",
  "registrationBillingAndFinancialUid",
  "registrationBillingAndFinancialUidInfo",
  "registrationBrandpromiseExpectationHelp",
  "registrationBrandpromiseExpectationImprove",
  "registrationBrandpromiseExpectationOther",
  "registrationBrandpromiseExpectationPartner",
  "registrationBrandpromiseHeadline",
  "registrationBrandpromiseOtherLabel",
  "registrationCompleteSetUpHeading",
  "registrationCompleteSetUpHeadingFailed",
  "registrationCompleteSetUpHeadingSucceeded",
  "registrationCompleteSetUpSubheadingFailed",
  "registrationCompleteSetUpSubheadingSucceededFirstParagraph",
  "registrationCompleteSetUpSubheadingSucceededSecondParagraph",
  "registrationCompleteSetUpSubheadingSucceededSupportEmail",
  "registrationCompleteSetUpSubheadingSucceededThirdParagraph",
  "registrationCreateAnAdminUserHeading",
  "registrationCreateAnAdminUserSubheading",
  "registrationLegalCompanyAddress",
  "registrationLegalCompanyContactEmail",
  "registrationLegalCompanyHeading",
  "registrationLegalCompanySubheading",
  "registrationTermsAndConditionsAcceptTerms",
  "registrationTermsAndConditionsAfterThat",
  "registrationTermsAndConditionsBySigningThisDocument",
  "registrationTermsAndConditionsContractEnd",
  "registrationTermsAndConditionsContractStart",
  "registrationTermsAndConditionsDiscount",
  "registrationTermsAndConditionsDiscountValue",
  "registrationTermsAndConditionsEversportsAgreement",
  "registrationTermsAndConditionsHeading",
  "registrationTermsAndConditionsPackage",
  "registrationTermsAndConditionsPartnerCompaniesAgreement",
  "registrationTermsAndConditionsPer",
  "registrationTermsAndConditionsPleaseEnterYourComplete",
  "registrationTermsAndConditionsRate",
  "registrationTermsAndConditionsRole",
  "registrationTermsAndConditionsYouAgree",
  "registrationTermsAndConditionsYouGivePermissionToEversports",
  "registrationVenueHeading",
  "registrationVenueStudioAddress",
  "registrationVenueStudioName",
  "registrationVenueSubheading",
  "startSetUpHeading",
  "startSetUpSubheading",
  "startTypingForGoogleSearch",
  "stepperActionBack",
  "stepperActionCompleteRegistration",
  "stepperActionContinue",
  "stepperActionStartSettingUp",
  "studioTypes",
  "studioTypesInfo",
  "userEmail",
  "userFirstName",
  "userLastName",
  "website",
  "yes",
], {
  de: () => import("./locales/de.js"),
  en: () => import("./locales/en.js"),
  es: () => import("./locales/es.js"),
  nl: () => import("./locales/nl.js"),
  fr: () => import("./locales/fr.js"),
  it: () => import("./locales/it.js"),
}, "en");
