import { ApolloClient } from '@apollo/client'
import { AsyncResult, failure, success } from '../../result'
import {
  CreateCompanyContractPdfDocument,
  CreateCompanyContractPdfMutation,
  CreateCompanyContractPdfMutationVariables,
} from '../../graphql'

const createCompanyContractPDF =
  (client: ApolloClient<unknown>) =>
  async (
    variables: CreateCompanyContractPdfMutationVariables,
  ): AsyncResult<CreateCompanyContractPdfMutation['createCompanyContractPdf']> => {
    if (!variables.company || !variables.token) {
      return failure(new Error('The required information from the previous steps were not filled out'))
    }

    const result = await client.mutate<CreateCompanyContractPdfMutation, CreateCompanyContractPdfMutationVariables>({
      mutation: CreateCompanyContractPdfDocument,
      variables,
    })

    if (!result.data || !result.data.createCompanyContractPdf) {
      return failure(new Error('No data is being returned from mutation'))
    }

    if (result.data.createCompanyContractPdf.__typename === 'ExpectedErrors') {
      // TODO: Implement some error handling. No idea how
      return failure(
        new Error('An expected error happened: ' + JSON.stringify(result.data.createCompanyContractPdf.errors)),
      )
    }

    return success(result.data.createCompanyContractPdf)
  }

export default createCompanyContractPDF
