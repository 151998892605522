import hydrateReactClient from '@eversports/react-app-base/hydrate-react-client'

import fragmentTypes from './graphql-manager-possible-types'

import App from './container/App'
import { getSharedConfig } from './get-config'
import getLocalizationProvider from './LocalizationProvider'
import { setWebpackPublicPath } from './config/set-public-path'

const sharedConfig = getSharedConfig()

if (sharedConfig.PUBLIC_PATH && sharedConfig.PUBLIC_PATH !== '/') {
  setWebpackPublicPath(sharedConfig.PUBLIC_PATH)
}

hydrateReactClient({
  App,
  getLocalizationProvider,
  apolloOptions: { possibleTypes: fragmentTypes.possibleTypes, uri: sharedConfig.ONBOARDING_CLIENT_GRAPHQL_URL },
}).catch((error) => console.error(error))
