import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { Localized } from '../../localization/react'

const RegistrationSetupCompletionFailed: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Flex flexDirection="column" verticalSpacing="default" fullWidth>
    <Heading is="h2">
      <Localized id="registration-complete-set-up-heading-failed" />
    </Heading>
    <Text>
      <Localized id="registration-complete-set-up-subheading-failed" />
    </Text>
  </Flex>
)

export default RegistrationSetupCompletionFailed
