import * as React from 'react'
import { ReactNode } from 'react'

import AutoCompleteNotFound from './AutoCompleteNotFound'
import AutoCompleteSearching from './AutoCompleteSearching'
import {
  AutoCompleteSuggestionProps,
  AutoCompleteSuggestionsContextProps,
  AutoCompleteSuggestionsProvider,
} from './AutoCompleteSuggestionContext'

export interface AutoCompleteSuggestionsProps<Suggestion extends AutoCompleteSuggestionProps>
  extends AutoCompleteSuggestionsContextProps<Suggestion> {
  notFoundMessage?: string
  children: (props: AutoCompleteSuggestionsContextProps<Suggestion>) => ReactNode
}

interface Props<Suggestion extends AutoCompleteSuggestionProps> extends AutoCompleteSuggestionsProps<Suggestion> {
  isSearching: boolean
}

const AutoCompleteSuggestions = <Suggestion extends AutoCompleteSuggestionProps>({
  suggestions,
  query,
  children,
  notFoundMessage,
  onClick,
  isSearching,
}: Props<Suggestion>) => {
  const contextValue = {
    suggestions,
    query,
    onClick,
  }

  if (isSearching) {
    return <AutoCompleteSearching />
  }

  if (suggestions && suggestions.length > 0) {
    return (
      <AutoCompleteSuggestionsProvider value={contextValue}>
        {typeof children === 'function' ? children({ suggestions, query, onClick }) : children}
      </AutoCompleteSuggestionsProvider>
    )
  }

  return <AutoCompleteNotFound notFoundMessage={notFoundMessage} />
}

export default AutoCompleteSuggestions
