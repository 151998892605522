import { useApolloClient } from '@apollo/client'
import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import Placeholder from '@eversports/react-components/primitives/Placeholder'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { parse } from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import RegistrationContext from '../../registration-context'

import createOnBoardingCompany from './create-onboarding-company'
import SetupCompletionFailed from './RegistrationSetupCompletionFailed'
import SetupCompletionSuccessful from './RegistrationSetupCompletionSuccessful'

const RegistrationCompleteSetUp: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ location }) => {
  const registrationContext = useContext(RegistrationContext)
  const [isProcessSuccessfullyCreated, setIsProcessSuccessfullyCreated] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const client = useApolloClient()
  const { token } = parse(location.search)

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  if (!token) {
    throw new Error('The required token was not defined')
  }

  useEffect(() => {
    finalizeCompanyOnBoardingProcess().catch((error) => {
      ClientLogger.error('Finalized Company Onboarding Failed', error)
    })
  }, [])

  const finalizeCompanyOnBoardingProcess = async () => {
    setIsLoading(true)
    const result = await createOnBoardingCompany(client)({
      admin: registrationContext.userAdminInformation,
      signature: registrationContext.termsAndConditionsInformation,
      facility: {
        name: registrationContext.venueInformation.name,
        website: registrationContext.venueInformation.website,
        location: {
          street: registrationContext.venueInformation.street,
          streetNumber: registrationContext.venueInformation.streetNumber,
          zip: registrationContext.venueInformation.zip,
          city: registrationContext.venueInformation.city,
          country: registrationContext.venueInformation.country,
          latitude: registrationContext.venueInformation.latitude,
          longitude: registrationContext.venueInformation.longitude,
        },
        brandPromise: registrationContext.venueInformation.brandPromise.join(','),
        brandPromiseText: registrationContext.venueInformation.brandPromiseText,
      },
      company: {
        ...registrationContext.billingAndFinancialInformation,
        location: {
          street: registrationContext.legalCompanyInformation.street,
          streetNumber: registrationContext.legalCompanyInformation.streetNumber,
          zip: registrationContext.legalCompanyInformation.zip,
          city: registrationContext.legalCompanyInformation.city,
          country: registrationContext.legalCompanyInformation.country,
          latitude: registrationContext.legalCompanyInformation.latitude,
          longitude: registrationContext.legalCompanyInformation.longitude,
        },
        name: registrationContext.legalCompanyInformation.name,
        email: registrationContext.legalCompanyInformation.email,
        phone: `${registrationContext.legalCompanyInformation.countryCallingCode} ${registrationContext.legalCompanyInformation.phoneNumber}`,
      },
      token: token as string,
    })

    if (result.isError) {
      // TODO: Think how to handle this error
      ClientLogger.error('Finalized Company Onboarding Failed', result.error)
    } else if (result.value.__typename === 'CreateOnboardingCompanyResult') {
      setIsProcessSuccessfullyCreated(result.value.success)
    }

    setIsLoading(false)
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <Placeholder height={250} width="100%" center>
          <CircularProgress />
        </Placeholder>
      )
    }

    if (!isProcessSuccessfullyCreated) {
      return <SetupCompletionFailed />
    }

    return <SetupCompletionSuccessful email={registrationContext.userAdminInformation.email} />
  }

  return renderContent()
}

export default withRouter(RegistrationCompleteSetUp)
