import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface Props {
  subject: React.ReactNode
  value: string | React.ReactNode
}

const useStyles = makeStyles({
  subject: {
    width: '50%',
  },
  subjectDescription: {
    width: '50%',
  },
})

const RegistrationTermsAndConditionsDataSubject: React.FC<React.PropsWithChildren<Props>> = ({ subject, value }) => {
  const classes = useStyles()
  return (
    <Flex horizontalSpacing="dense" fullWidth>
      <Text className={classes.subject} fontWeight="bold">
        {subject}
      </Text>
      <Text className={classes.subjectDescription}>{value}</Text>
    </Flex>
  )
}

export default RegistrationTermsAndConditionsDataSubject
