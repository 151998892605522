import combine from '@eversports/core-io/combine'
import * as s from '@eversports/core-io/sanitizer'
import * as v from '@eversports/core-io/validator'

export default combine({
  sanitizer: s.sanitizer({
    firstName: s.string(),
    lastName: s.string(),
    email: s.string(),
    countryCallingCode: s.string(),
    phoneNumber: s.string(),
    password: s.string(),
    question: s.optional(s.string()),
  }),
  validator: v.validator({
    firstName: v.length({ min: 2 }),
    lastName: v.length({ min: 2 }),
    email: v.email(),
    countryCallingCode: v.length({ min: 3, max: 3 }),
    phoneNumber: v.telephone({ withoutCountryCode: true }),
    password: v.length({ min: 8 }),
  }),
  fieldNameIds: {
    firstName: 'user-first-name',
    lastName: 'user-last-name',
    email: 'user-mail',
    countryCallingCode: 'country-calling-code',
    phoneNumber: 'phone-number',
    password: 'password',
    question: 'free-trial-create-an-admin-user-question',
  },
})
