import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import * as React from 'react'

import Text from '../Text'

export interface Props {
  suggestion: string
  query: string
}

const AutoCompleteHighLight: React.FC<React.PropsWithChildren<Props>> = ({ suggestion, query }) => {
  // match: calculates the characters to highlight in text based on query.
  // It returns an array of pairs. Every pair [a, b] means that text.slice(a, b) should be highlighted.
  // please see: https://github.com/moroshko/autosuggest-highlight
  const matches = match(suggestion, query)
  // parse: Breaks the given text to parts based on matches.
  // It returns an array of text parts by specifying whether each part should be highlighted or not.
  // please see: https://www.npmjs.com/package/autosuggest-highlight
  const parts = parse(suggestion, matches)

  return (
    <div>
      {parts.map(({ text, highlight }, index) => (
        <Text is="span" key={index} fontWeight={highlight ? 'bold' : 'normal'}>
          {text}
        </Text>
      ))}
    </div>
  )
}

export default AutoCompleteHighLight
