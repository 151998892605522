import * as React from 'react'

import { Localized } from '../../localization/react'
import MenuItem from '../MenuItem'
import Text from '../Text'

interface Props {
  notFoundMessage?: string
}

const AutoCompleteNotFound: React.FC<React.PropsWithChildren<Props>> = ({ notFoundMessage }) => (
  <MenuItem>
    <Text size="small">{notFoundMessage || <Localized id="autocomplete-results-not-found" />}</Text>
  </MenuItem>
)

export default AutoCompleteNotFound
