import { Theme } from '@eversports/react-components/design-tokens/theme'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { default as MuiStepper } from '@mui/material/Stepper'
import { makeStyles } from '@mui/styles'
import React, { useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import RegistrationContext from '../../registration-context'

import StepperLabelContent from './StepperLabelContent'

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  step: ({ stepWidth }: { stepWidth: string }) => ({
    width: stepWidth,
  }),
  stepLabel: {
    visibility: 'hidden',
    height: 0,
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },
}))

const Stepper: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history, location }) => {
  const registrationContext = useContext(RegistrationContext)

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const steps = registrationContext.steps
  const activePath = location.pathname
  const activeStepIndex = steps.findIndex((step) => step.id === activePath)
  const previousSteps = steps.slice(0, activeStepIndex)

  const stepWidth = steps.length > 0 ? `${100 / steps.length}%` : 'auto'
  const classes = useStyles({ stepWidth })

  useEffect(() => {
    checkPreviousSteps()
  }, [steps])

  const checkPreviousSteps = () => {
    if (previousSteps.length > 0) {
      const previousInvalidStep = previousSteps.find((step) => !step.isValid)
      if (previousInvalidStep) {
        history.replace(`${previousInvalidStep.id}${location.search}`)
      }
    }
  }

  return (
    <MuiStepper activeStep={activeStepIndex} className={classes.stepper} alternativeLabel>
      {steps.map((step) => (
        <Step key={step.id} classes={{ root: classes.step }}>
          <StepLabel classes={{ labelContainer: classes.stepLabel }}>
            <StepperLabelContent step={step.id} />
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  )
}

export default withRouter(Stepper)
