import { useEffect, useState } from 'react'

interface Props {
  src: HTMLScriptElement['src']
  onLoad?: HTMLScriptElement['onload']
  onError?: HTMLScriptElement['onerror']
  initialize?: () => void
}

// This code was created based on this: https://usehooks.com/useScript/
const useScript = ({ src, onLoad, onError, initialize }: Props): [boolean, boolean] => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    isLoaded: false,
    hasError: false,
  })

  useEffect(() => {
    // If the document already includes the src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (state.isLoaded || document.querySelector(`script[src="${src}"]`)) {
      return
    }
    if (initialize) {
      initialize()
    }
    // Create script
    const script = document.createElement('script')
    script.src = src
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.onload = onLoad || null
    script.onerror = onError || null
    const scripts = document.getElementsByTagName('script')[0]
    scripts.parentNode!.insertBefore(script, scripts)

    // Script event listener callbacks for load and error
    const handleScriptLoad = () => {
      setState({
        isLoaded: true,
        hasError: false,
      })
    }

    // Script event listener callbacks for load and error
    const handleScriptError = () => {
      script.remove()
      setState({
        isLoaded: true,
        hasError: true,
      })
    }

    script.addEventListener('load', handleScriptLoad)
    script.addEventListener('error', handleScriptError)

    // Add script to document body
    document.body.appendChild(script)

    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener('load', handleScriptLoad)
      script.removeEventListener('error', handleScriptError)
    }
  }, [src]) // Only re-run effect if script src changes

  return [state.isLoaded, state.hasError]
}

export default useScript
