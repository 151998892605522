import { Theme } from '@eversports/react-components/design-tokens/theme'
import BaseLink from '@eversports/react-components/primitives/BaseLink'
import Checkbox from '@eversports/react-components/primitives/Checkbox'
import Flex from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { Localized } from '../../localization/react'

const useStyles = makeStyles(({ layoutSpacing }: Theme) => ({
  list: {
    '& > *:first-child': {
      paddingTop: layoutSpacing.none,
    },
  },
  link: {
    textDecoration: 'underline',
  },
}))

interface Props {
  companyContractPdfURL: string
  dataProcessingAgreementURL: string
}

const RegistrationTermsAndConditionsDescription: React.FC<React.PropsWithChildren<Props>> = ({
  companyContractPdfURL,
  dataProcessingAgreementURL,
}) => {
  const classes = useStyles()
  return (
    <Flex flexDirection="column" verticalSpacing="default" fullWidth>
      <Text>
        <Localized id="registration-terms-and-conditions-by-signing-this-document" />
      </Text>

      <Flex flexDirection="row" alignItems="flex-start" is="label">
        <Checkbox
          value="agreedToEversportsAgreement"
          name="userAgreedToAllTermsAndConditions"
          id="user-agreed-to-all-terms-and-conditions"
        />
        <div>
          <Localized
            id="registration-terms-and-conditions-you-agree"
            params={{
              eversportsAgreement: (
                <BaseLink to={companyContractPdfURL} external blank>
                  <Text is="span" className={classes.link}>
                    <Localized id="registration-terms-and-conditions-eversports-agreement" />
                  </Text>
                </BaseLink>
              ),
              partnerCompaniesAgreement: (
                <BaseLink to={dataProcessingAgreementURL} external blank>
                  <Text is="span" className={classes.link}>
                    <Localized id="registration-terms-and-conditions-partner-companies-agreement" />
                  </Text>
                </BaseLink>
              ),
            }}
          />
        </div>
      </Flex>

      <Flex flexDirection="row" alignItems="flex-start" is="label">
        <Checkbox value="agreedToDeductCosts" name="userAgreedToAllTermsAndConditions" />
        <Localized id="registration-terms-and-conditions-you-give-permission-to-eversports" />
      </Flex>

      <Flex flexDirection="row" alignItems="flex-start" is="label">
        <Checkbox value="allowedToSign" name="userAgreedToAllTermsAndConditions" />
        <Localized id="registration-terms-and-conditions-accept-terms" />
      </Flex>

      <Text>
        <Localized
          id="registration-terms-and-conditions-please-enter-your-complete"
          params={{
            completeRegistration: <Localized id="stepper-action-complete-registration" />,
          }}
        />
      </Text>

      <Text>
        <Localized id="registration-terms-and-conditions-after-that" />
      </Text>
    </Flex>
  )
}

export default RegistrationTermsAndConditionsDescription
