import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { Localized } from '../../localization/react'

interface Props {
  email: string
}

const RegistrationSetupCompletionSuccessful: React.FC<React.PropsWithChildren<Props>> = ({ email }) => (
  <Flex flexDirection="column" verticalSpacing="default" fullWidth>
    <Heading is="h2">
      <Localized id="registration-complete-set-up-heading-succeeded" />
    </Heading>
    <Text>
      <Localized
        id="registration-complete-set-up-subheading-succeeded-first-paragraph"
        params={{
          email: (
            <Text is="span" fontWeight="bold">
              {email}
            </Text>
          ),
          supportEmail: (
            <Text is="span">
              <Localized id="registration-complete-set-up-subheading-succeeded-support-email" />
            </Text>
          ),
        }}
      />
    </Text>
    <Text>
      <Localized id="registration-complete-set-up-subheading-succeeded-second-paragraph" />
    </Text>
    <Text size="x-small" fontWeight="bold">
      <Localized id="registration-complete-set-up-subheading-succeeded-third-paragraph" />
    </Text>
  </Flex>
)

export default RegistrationSetupCompletionSuccessful
