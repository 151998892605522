import combine from '@eversports/core-io/combine'
import * as s from '@eversports/core-io/sanitizer'
import * as v from '@eversports/core-io/validator'

export default combine({
  sanitizer: s.sanitizer({
    name: s.string(),
    street: s.string(),
    streetNumber: s.string(),
    city: s.string(),
    zip: s.string(),
    country: s.string(),
    longitude: s.optional(s.number()),
    latitude: s.optional(s.number()),
    email: s.string(),
    countryCallingCode: s.string(),
    phoneNumber: s.string(),
  }),
  validator: v.validator({
    name: v.length({ min: 2 }),
    street: v.length({ min: 2 }),
    streetNumber: v.length({ min: 1 }),
    zip: v.length({ min: 2 }),
    city: v.length({ min: 2 }),
    country: v.length({ min: 2 }),
    email: v.email(),
    countryCallingCode: v.length({ min: 3, max: 4 }),
    phoneNumber: v.telephone({ withoutCountryCode: true }),
  }),
  fieldNameIds: {
    name: 'company-name',
    email: 'registration-legal-company-contact-email',
    countryCallingCode: 'country-calling-code',
    phoneNumber: 'phone-number',
    streetNumber: 'address-number',
    street: 'address-street',
    zip: 'address-zip',
    country: 'address-country',
    city: 'address-city',
  },
})
