import * as React from 'react'

import { assertNever } from '../../utilities/assertNever'
import { Localized } from '../../localization/react'

export type FormatVariants = 'short' | 'medium' | 'long' | 'full'
export type DateValue = Date | string

interface Props {
  variant: FormatVariants
  value: DateValue
}

type FormatDateRef = HTMLElementTagNameMap['time']

// eslint-disable-next-line no-shadow
export const FormatDate = React.forwardRef<FormatDateRef, Props>(function FormatDate({ variant, value }, ref) {
  const date = new Date(value)
  const dateString = date.toISOString()

  switch (variant) {
    case 'short':
      return (
        <time dateTime={dateString} ref={ref}>
          <Localized id="short-format-date" params={{ date }} />
        </time>
      )
    case 'medium':
      return (
        <time dateTime={dateString} ref={ref}>
          <Localized id="medium-format-date" params={{ date }} />
        </time>
      )
    case 'long':
      return (
        <time dateTime={dateString} ref={ref}>
          <Localized id="long-format-date" params={{ date }} />
        </time>
      )
    case 'full':
      return (
        <time dateTime={dateString} ref={ref}>
          <Localized id="full-format-date" params={{ date }} />
        </time>
      )
    default:
      return assertNever(variant)
  }
})

export default FormatDate
