import React from 'react'

import { RegistrationRoutes } from './routes'

export interface AddressProps {
  street: string
  streetNumber: string
  zip: string
  city: string
  country: string
  latitude?: number
  longitude?: number
}

export interface RegistrationVenueProps extends AddressProps {
  address: string
  isAutoCompleteAddress: boolean
  name: string
  website: string
  brandPromise: Array<string>
  brandPromiseText: string
}

export interface RegistrationLegalCompanyProps extends AddressProps {
  address: string
  isAutoCompleteAddress: boolean
  name: string
  email: string
  countryCallingCode: string
  phoneNumber: string
}

export interface RegistrationBillingAndFinancialProps {
  iban: string
  bic: string
  uid: string
}

export interface RegistrationTermsAndConditionsProps {
  firstname: string
  lastname: string
  role: string
}

export interface RegistrationCreateAnAdminUserProps {
  firstname: string
  lastname: string
  email: string
  password: string
}

export interface Step {
  id: RegistrationRoutes
  isValid: boolean
}

export interface RegistrationContextProps {
  venueInformation: RegistrationVenueProps
  setVenueInformation: (venueInformation: RegistrationVenueProps) => void
  userAdminInformation: RegistrationCreateAnAdminUserProps
  setUserAdminInformation: (userAdminInformation: RegistrationCreateAnAdminUserProps) => void
  billingAndFinancialInformation: RegistrationBillingAndFinancialProps
  setBillingAndFinancialInformation: (billingAndFinancialInformation: RegistrationBillingAndFinancialProps) => void
  termsAndConditionsInformation: RegistrationTermsAndConditionsProps
  setTermsAndConditionsInformation: (termsAndConditionsInformation: RegistrationTermsAndConditionsProps) => void
  legalCompanyInformation: RegistrationLegalCompanyProps
  setLegalCompanyInformation: (legalCompanyInformation: RegistrationLegalCompanyProps) => void
  steps: Array<Step>
  setValidStep: (stepId: RegistrationRoutes) => void
}

const RegistrationContext = React.createContext<undefined | RegistrationContextProps>(undefined)

export default RegistrationContext
