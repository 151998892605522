import * as React from 'react'

export const AutoCompleteSuggestionsContext = React.createContext<AutoCompleteSuggestionsContextProps<any> | undefined>(
  undefined,
)

export const AutoCompleteSuggestionsProvider = AutoCompleteSuggestionsContext.Provider

export interface AutoCompleteSuggestionsContextProps<Suggestion extends AutoCompleteSuggestionProps> {
  onClick: (suggestion: Suggestion['id']) => void
  suggestions: Array<Suggestion>
  query: string
}

export interface AutoCompleteSuggestionProps {
  id: string
}
