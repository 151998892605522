import { Query } from '@apollo/client/react/components'
import Checkbox from '@eversports/react-components/primitives/Checkbox'
import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import { VerticalFlex } from '@eversports/react-components/primitives/Flex'
import MenuItem from '@eversports/react-components/primitives/MenuItem'
import Placeholder from '@eversports/react-components/primitives/Placeholder'
import Select from '@eversports/react-components/primitives/Select'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { Localized } from '../localization/react'
import { SelectStudioTypesSportsDocument, SelectStudioTypesSportsQuery } from '../graphql'

const SelectStudioTypes: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Query<SelectStudioTypesSportsQuery> query={SelectStudioTypesSportsDocument}>
    {({ error, data }) => {
      if (error) {
        return <>{error.message}</>
      }

      if (data) {
        const convertedSports = data.sports.map((sport) => ({ value: sport.name, id: sport.id }))
        return (
          <VerticalFlex verticalSpacing="dense">
            <Select
              name="studioTypes"
              label={<Localized id="studio-types" />}
              multipleSelectRenderValues={convertedSports}
              multiple
              required
              fullWidth
            >
              {convertedSports.map((sport) => (
                <MenuItem key={sport.id} value={sport.id}>
                  <Checkbox value={sport.id} name="studioTypes" />
                  <Text>{sport.value}</Text>
                </MenuItem>
              ))}
            </Select>
            <Text size="x-small" color="subtleText">
              <Localized id="studio-types-info" />
            </Text>
          </VerticalFlex>
        )
      }

      return (
        <Placeholder height={250} width="100%" center>
          <CircularProgress />
        </Placeholder>
      )
    }}
  </Query>
)

export default SelectStudioTypes
