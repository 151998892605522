import React from 'react'
import { Route, Switch } from 'react-router'

import MatchedRegistrationRouteLayout from '../components/MatchedRegistrationRouteLayout/MatchedRegistrationRouteLayout'
import NotFound from '../pages/NotFound'
import RegistrationBillingAndFinancial from '../pages/RegistrationBillingAndFinancial'
import RegistrationCompleteSetUp from '../pages/RegistrationCompleteSetUp'
import RegistrationCreateAnAdminUser from '../pages/RegistrationCreateAnAdminUser'
import RegistrationLegalCompany from '../pages/RegistrationLegalCompany'
import RegistrationTermsAndConditions from '../pages/RegistrationTermsAndConditions'
import RegistrationVenue from '../pages/RegistrationVenue'
import { RegistrationRoutes as routes } from '../routes'

const RegistrationRoutes: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Switch>
    <Route
      exact
      path={routes.VENUE}
      component={() => (
        <MatchedRegistrationRouteLayout>
          <RegistrationVenue />
        </MatchedRegistrationRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.LEGAL_COMPANY}
      render={() => (
        <MatchedRegistrationRouteLayout>
          <RegistrationLegalCompany />
        </MatchedRegistrationRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.BILLING_AND_FINANCIAL}
      render={() => (
        <MatchedRegistrationRouteLayout>
          <RegistrationBillingAndFinancial />
        </MatchedRegistrationRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.CREATE_AN_ADMIN_USER}
      render={() => (
        <MatchedRegistrationRouteLayout>
          <RegistrationCreateAnAdminUser />
        </MatchedRegistrationRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.TERMS_AND_CONDITIONS}
      render={() => (
        <MatchedRegistrationRouteLayout>
          <RegistrationTermsAndConditions />
        </MatchedRegistrationRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.COMPLETE_SET_UP}
      render={() => (
        <MatchedRegistrationRouteLayout>
          <RegistrationCompleteSetUp />
        </MatchedRegistrationRouteLayout>
      )}
    />
    <Route render={() => <NotFound />} />
  </Switch>
)

export default RegistrationRoutes
