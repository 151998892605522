import { useEffect, useRef, useState } from 'react'

// please see: https://github.com/bhaskarGyan/use-throttle/blob/master/src/index.js
const useThrottle = (value: string, delay: number) => {
  // State and setters for throttled value
  const [throttledValue, setThrottledValue] = useState(value)
  // Time at which the last request was executed
  const lastRan = useRef(Date.now())

  useEffect(() => {
    // Once the last call ends, the next invocation will fire and the process repeats.
    const handler = setTimeout(
      () => {
        if (Date.now() - lastRan.current >= delay) {
          setThrottledValue(value)
          lastRan.current = Date.now()
        }
        // Another request will not get fire until the throttle period has passed
      },
      delay - (Date.now() - lastRan.current),
    )

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Only re-call effect if value or delay changes

  return throttledValue
}

export default useThrottle
