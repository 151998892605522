import * as React from 'react'

interface GoogleRecaptchaAPIContextTypeLoading {
  type: 'loading'
}

interface GoogleRecaptchaAPIContextTypeSuccess {
  type: 'success'
  // eslint-disable-next-line no-undef
  grecaptcha: typeof grecaptcha
}

interface GoogleRecaptchaAPIContextTypeError {
  type: 'error'
  message: string
}

export type GoogleRecaptchaAPIContextType =
  | GoogleRecaptchaAPIContextTypeLoading
  | GoogleRecaptchaAPIContextTypeSuccess
  | GoogleRecaptchaAPIContextTypeError

const GoogleRecaptchaAPIContext = React.createContext<undefined | GoogleRecaptchaAPIContextType>(undefined)

export default GoogleRecaptchaAPIContext
