import { Theme } from '@eversports/react-components/design-tokens/theme'
import TextField from '@eversports/react-components/primitives/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { Localized } from '../localization/react'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  iconButton: {
    padding: spacing(1),
  },
}))

const TextFieldPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  return (
    <TextField
      type={values.showPassword ? 'text' : 'password'}
      name="password"
      label={<Localized id="password" />}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handleClickShowPassword} className={classes.iconButton} size="large">
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  )
}

export default TextFieldPassword
