import { ApolloClient } from '@apollo/client'
import { AsyncResult, failure, success } from '../../result'
import { ValidateVatDocument, ValidateVatMutation, ValidateVatMutationVariables } from '../../graphql'

const validateVat =
  (client: ApolloClient<unknown>) =>
  async ({ vat }: ValidateVatMutationVariables): AsyncResult<ValidateVatMutation['validateVat']> => {
    const result = await client.mutate<ValidateVatMutation, ValidateVatMutationVariables>({
      mutation: ValidateVatDocument,
      variables: {
        vat,
      },
    })

    if (!result.data || !result.data.validateVat) {
      return failure(new Error('No data is being returned from mutation'))
    }

    if (result.data.validateVat.__typename === 'ExpectedErrors') {
      return failure(new Error('An expected error happened: ' + JSON.stringify(result.data.validateVat.errors)))
    }

    return success(result.data.validateVat)
  }

export default validateVat
