import React from 'react'
import { Route, Switch } from 'react-router'

import MatchedFreeTrialRouteLayout from '../components/MatchedFreeTrialRouteLayout'
import FreeTrialCompleteSetUp from '../pages/FreeTrialCompleteSetUp'
import FreeTrialCreateAnAdminUser from '../pages/FreeTrialCreateAnAdminUser'
import FreeTrialVenue from '../pages/FreeTrialVenue'
import NotFound from '../pages/NotFound'
import { FreeTrialRoutes as routes } from '../routes'

const FreeTrialRoutes: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Switch>
    <Route
      exact
      path={routes.VENUE}
      render={() => (
        <MatchedFreeTrialRouteLayout>
          <FreeTrialVenue />
        </MatchedFreeTrialRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.CREATE_AN_ADMIN_USER}
      render={() => (
        <MatchedFreeTrialRouteLayout>
          <FreeTrialCreateAnAdminUser />
        </MatchedFreeTrialRouteLayout>
      )}
    />
    <Route
      exact
      path={routes.COMPLETE_SET_UP}
      render={() => (
        <MatchedFreeTrialRouteLayout>
          <FreeTrialCompleteSetUp />
        </MatchedFreeTrialRouteLayout>
      )}
    />
    <Route render={() => <NotFound />} />
  </Switch>
)

export default FreeTrialRoutes
