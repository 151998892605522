import { Locales } from '../../localization/react'

function getDataProcessingAgreementUrl(urlLocale: Locales): string {
  switch (urlLocale) {
    case 'de':
      return 'https://www.eversportsmanager.com/de/agb/'
    case 'nl':
      return 'https://www.eversportsmanager.com/nl/avz/'
    case 'fr':
      return 'https://www.eversportsmanager.com/fr/conditions-generales/'
    case 'it':
      return 'https://www.eversportsmanager.com/it/tgc/'
    case 'es':
      return 'https://www.eversportsmanager.com/es/cgc/'
    default:
      return 'https://www.eversportsmanager.com/gtc/'
  }
}

export default getDataProcessingAgreementUrl
