export enum RootRoutes {
  REGISTRATION = '/registration',
  FREE_TRIAL = '/free-trial',
}

export enum RegistrationRoutes {
  VENUE = '/registration/venue',
  LEGAL_COMPANY = '/registration/legal-company',
  BILLING_AND_FINANCIAL = '/registration/billing-and-financial',
  CREATE_AN_ADMIN_USER = '/registration/create-a-user-admin',
  TERMS_AND_CONDITIONS = '/registration/terms-and-conditions',
  COMPLETE_SET_UP = '/registration/complete-set-up',
}

export enum FreeTrialRoutes {
  VENUE = '/free-trial/venue',
  CREATE_AN_ADMIN_USER = '/free-trial/create-a-user-admin',
  COMPLETE_SET_UP = '/free-trial/complete-set-up',
}
