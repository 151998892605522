import ActionOutcome from '@eversports/react-components/patterns/ActionOutcome'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { Localized } from '../localization/react'

const InvalidURL: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActionOutcome type="error" justifyContent="center" flexGrow={1}>
    <Heading data-testid="not-found" is="h1">
      <Localized id="invalid-url-heading" />
    </Heading>
    <Text>
      <Localized id="invalid-url-subheading" />
    </Text>
  </ActionOutcome>
)

export default InvalidURL
