import { Theme } from '@eversports/react-components/design-tokens/theme'
import { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

import Logo from './Logo'

const useStyles = makeStyles(({ palette, layoutSpacing, shadows, breakpoints }: Theme) => ({
  container: {
    width: '100%',
    height: 60,
    minHeight: 60,
    boxShadow: shadows[4],
    background: palette.white,
    paddingRight: layoutSpacing.default,
    paddingLeft: layoutSpacing.default,
    [breakpoints.up('sm')]: {
      paddingRight: layoutSpacing.wide,
      paddingLeft: layoutSpacing.wide,
    },
  },
}))

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()
  return (
    <SimpleFlex justifyContent="center" alignItems="center" className={classes.container}>
      <Logo />
    </SimpleFlex>
  )
}

export default Header
