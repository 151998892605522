import { default as MuiMenuItem, MenuItemProps } from '@mui/material/MenuItem'
import * as React from 'react'

type MenuItemRef = HTMLElementTagNameMap['li']

// eslint-disable-next-line no-shadow
const MenuItem = React.forwardRef<MenuItemRef, Omit<MenuItemProps, 'button'>>(function MenuItem(props, ref) {
  return <MuiMenuItem {...props} ref={ref} />
})

export default MenuItem
