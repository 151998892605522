import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'

import { Localized } from '../../localization/react'

const useStyles = makeStyles({
  resendEmail: {
    cursor: 'pointer',
  },
})

const FreeTrialCompleteSetUp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()
  useEffect(() => {
    finalizeFreeTrialProcess().catch((error) => {
      ClientLogger.error('Finalized Company Onboarding Failed', error)
    })
  }, [])

  const finalizeFreeTrialProcess = async () => {
    console.log('backend missing!')
  }

  const handleResendEmail = () => {
    console.log('backend missing!')
  }

  return (
    <Flex flexDirection="column" verticalSpacing="default" fullWidth>
      <Heading is="h2">
        <Localized id="free-trial-complete-set-up-heading" />
      </Heading>
      <Text color="subtleText">
        <Localized id="free-trial-complete-set-up-subheading-first-paragraph" />
      </Text>
      <Text color="subtleText">
        <Localized id="free-trial-complete-set-up-subheading-second-paragraph" />
      </Text>
      <Flex horizontalSpacing="dense">
        <Text is="span" color="subtleText">
          <Localized id="free-trial-complete-set-up-taking-too-long" />
        </Text>
        <Text is="span" color="primary.main" onClick={handleResendEmail} className={classes.resendEmail}>
          <Localized id="free-trial-complete-set-up-resend-email" />
        </Text>
      </Flex>
      <Flex horizontalSpacing="dense">
        <Text is="span" color="subtleText" size="small">
          <Localized id="free-trial-complete-set-up-get-in-touch" />
        </Text>
        <Text is="span" size="small">
          <Localized id="free-trial-complete-set-up-get-in-touch-email" />
        </Text>
      </Flex>
    </Flex>
  )
}

export default FreeTrialCompleteSetUp
