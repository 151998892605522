import * as React from 'react'

import { Localized } from '../../localization/react'

interface Props {
  value: number
  currencyCode: string
}

const MonetaryValue: React.FC<React.PropsWithChildren<Props>> = ({ value, currencyCode }) => (
  <Localized id="monetary-value" params={{ total: { value, currency: currencyCode } }} />
)

export default MonetaryValue
