import { CountryName } from './countries'

export interface CountryCallingCode {
  country: CountryName
  code: string
  iso: string
}

function getCountryCallingCodes(): Array<CountryCallingCode> {
  return [
    {
      country: CountryName.GERMANY,
      code: '+49',
      iso: 'DE',
    },
    {
      country: CountryName.AUSTRIA,
      code: '+43',
      iso: 'AT',
    },
    {
      country: CountryName.SLOVAKIA,
      code: '+421',
      iso: 'SK',
    },
    {
      country: CountryName.SWITZERLAND,
      code: '+41',
      iso: 'CH',
    },
    {
      country: CountryName.ITALY,
      code: '+39',
      iso: 'IT',
    },
    {
      country: CountryName.LUXEMBOURG,
      code: '+352',
      iso: 'LU',
    },
    {
      country: CountryName.SPAIN,
      code: '+34',
      iso: 'ES',
    },
    {
      country: CountryName.FRANCE,
      code: '+33',
      iso: 'FR',
    },
    {
      country: CountryName.BELGIUM,
      code: '+32',
      iso: 'BE',
    },
    {
      country: CountryName.NETHERLANDS,
      code: '+31',
      iso: 'NL',
    },
  ]
}

export function getCountryCallingCodeByIso(iso: string): CountryCallingCode {
  const found = getCountryCallingCodes().find((call) => call.iso === iso)
  return found || getCountryCallingCodes()[0]
}

export default getCountryCallingCodes
