import { GoogleRecaptchaAPIContextType } from './GoogleRecaptchaAPIContext'

interface Props {
  loaded: boolean
  error: boolean
}

const getContextValue = ({ loaded, error }: Props): GoogleRecaptchaAPIContextType => {
  if (error) {
    return {
      type: 'error',
      message: 'An error has occurred while loading google recaptcha api',
    }
  }

  if (loaded) {
    return {
      type: 'success',
      // eslint-disable-next-line no-undef
      grecaptcha,
    }
  }

  return {
    type: 'loading',
  }
}

export default getContextValue
