export type OneOf<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

// It's like typescripts Partial<T>, but with null instead of undefined
export type Nullable<T> = {
  [P in keyof T]: T[P] | null
}

export type RemoveUndefinedAndNullKeys<T> = {
  [K in keyof T]-?: Exclude<T[K], undefined | null>
}

export type FilterCondition<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}

export const removeUndefinedAndNullKeys = <T>(o: T) => {
  for (const k in o) {
    if (o[k] === undefined || o[k] === null) delete o[k]
  }
  return o as RemoveUndefinedAndNullKeys<T>
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends Record<string, unknown> ? RecursivePartial<T[P]> : T[P]
}

// Stolen from https://stackoverflow.com/a/57390160/6429345
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

// https://learn.microsoft.com/en-us/javascript/api/@azure/keyvault-certificates/requireatleastone?view=azure-node-latest
export type AtLeastOne<T> = { [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>> }[keyof T]

export type Defined<T> = T extends undefined ? never : T

export const assertNever = (value: never): never => {
  throw new Error(`Unexpected value: ${JSON.stringify(value)}`)
}
