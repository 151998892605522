import { HorizontalFlex, VerticalFlex } from '@eversports/react-components/primitives/Flex'
import TextField from '@eversports/react-components/primitives/TextField'
import React from 'react'

import { Localized } from '../localization/react'

import SelectCountry from './SelectCountry'

interface Props {
  className?: string
}

const ManualAddress: React.FC<React.PropsWithChildren<Props>> = ({ className }) => (
  <VerticalFlex verticalSpacing="default" className={className}>
    <TextField name="street" label={<Localized id="address-street" />} fullWidth />
    <HorizontalFlex horizontalSpacing="dense">
      <TextField name="streetNumber" label={<Localized id="address-number" />} fullWidth />
      <TextField name="city" label={<Localized id="address-city" />} fullWidth />
    </HorizontalFlex>
    <HorizontalFlex horizontalSpacing="dense">
      <TextField name="zip" label={<Localized id="address-zip" />} fullWidth />
      <SelectCountry />
    </HorizontalFlex>
  </VerticalFlex>
)

export default ManualAddress
