import * as React from 'react'

interface GoogleMapsAPIContextTypeLoading {
  type: 'loading'
}

interface GoogleMapsAPIContextTypeSuccess {
  type: 'success'
  // eslint-disable-next-line no-undef
  googleMaps: typeof google.maps
}

interface GoogleMapsAPIContextTypeError {
  type: 'error'
  message: string
}

export type GoogleMapsAPIContextType =
  | GoogleMapsAPIContextTypeLoading
  | GoogleMapsAPIContextTypeSuccess
  | GoogleMapsAPIContextTypeError

const GoogleMapsAPIContext = React.createContext<undefined | GoogleMapsAPIContextType>(undefined)

export default GoogleMapsAPIContext
