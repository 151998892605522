import { HorizontalFlex } from '@eversports/react-components/primitives/Flex'
import MenuItem from '@eversports/react-components/primitives/MenuItem'
import Select from '@eversports/react-components/primitives/Select'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import getCountryCallingCodes, { CountryCallingCode } from './get-country-calling-codes'
import PhoneNumberFieldCountryCallingCodeIcon from './PhoneNumberFieldCountryCallingCodeIcon'

const PhoneNumberFieldCountryCallingCodes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const countryCallingCodes: Array<CountryCallingCode> = getCountryCallingCodes()
  return (
    <Select name="countryCallingCode" required>
      {countryCallingCodes.map(({ country, code }) => (
        <MenuItem key={country} value={code}>
          <HorizontalFlex horizontalSpacing="dense" alignItems="center">
            <PhoneNumberFieldCountryCallingCodeIcon country={country} />
            <Text size="small">{code}</Text>
          </HorizontalFlex>
        </MenuItem>
      ))}
    </Select>
  )
}

export default PhoneNumberFieldCountryCallingCodes
