import * as React from 'react'

import { Localized } from '../../localization/react'
import MenuItem from '../MenuItem'
import Text from '../Text'

const AutoCompleteSearching: React.FC<React.PropsWithChildren<unknown>> = () => (
  <MenuItem>
    <Text size="small">
      <Localized id="autocomplete-searching" />
    </Text>
  </MenuItem>
)

export default AutoCompleteSearching
