import { RegistrationContextProps, RegistrationCreateAnAdminUserProps } from '../../registration-context'

function initialValues(registrationContext: RegistrationContextProps): RegistrationCreateAnAdminUserProps {
  return {
    ...registrationContext.userAdminInformation,
    email: registrationContext.userAdminInformation.email || registrationContext.legalCompanyInformation.email,
  }
}

export default initialValues
