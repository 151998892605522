import { IOError } from '@eversports/io-error'

import { MarkSanitizerFn, SanitizerFn } from './types'

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const sanitizer: MarkSanitizerFn = (s) => s as any

interface StringOptions {
  trim?: boolean
  toLowerCase?: boolean
}
/**
 * Convert the input into a valid string type, which is also trimmed by default
 */
// eslint-disable-next-line id-blacklist
export function string(params: StringOptions = {}): SanitizerFn<string> {
  const error = IOError.factory('invalid-string', params)
  return (input) => {
    if (typeof input !== 'string' && typeof input !== 'number') {
      throw error(input)
    }
    let str = String(input)
    if (params.trim !== false) {
      str = str.trim()
    }
    if (params.toLowerCase) {
      str = str.toLowerCase()
    }
    return str
  }
}

export function number(): SanitizerFn<number> {
  const error = IOError.factory('invalid-number')
  return (input) => {
    const num = Number(input)
    if (isNaN(num) || (!input && typeof input !== 'number')) {
      throw error(input)
    }
    return num
  }
}

interface BooleanOptions {
  strict?: boolean
}

// eslint-disable-next-line id-blacklist
export function boolean({ strict = false }: BooleanOptions = {}): SanitizerFn<boolean> {
  const error = IOError.factory('invalid-boolean')
  return (input) => {
    if (typeof input === 'boolean') {
      return input
    }
    const boolStr = String(input).trim()
    switch (boolStr) {
      case '0':
      case 'false':
      case 'no':
        return false
      // these are the valid cases when using `strict`
      case '1':
      case 'true':
      case 'yes':
        return true
      // NOTE(swatinem): because we are calling `String` above
      case '':
      case 'null':
      case 'undefined':
        if (!strict) {
          return false
        }
    }
    if (strict) {
      throw error(input)
    }
    return true
  }
}

export function date(): SanitizerFn<Date> {
  const error = IOError.factory('invalid-date')
  return (input) => {
    if (typeof input !== 'string' && typeof input !== 'number') {
      throw error(input)
    }
    // eslint-disable-next-line no-shadow
    const date = new Date(input)
    if (isNaN(date.getTime())) {
      throw error(input)
    }
    return date
  }
}

// eslint-disable-next-line no-shadow
export function optional<T>(sanitizer: SanitizerFn<T>): SanitizerFn<T | undefined> {
  return (input) => {
    if (typeof input === 'undefined' || input === null) {
      return undefined
    }
    return sanitizer(input)
  }
}
