export enum CountryName {
  AUSTRIA = 'AUSTRIA',
  BELGIUM = 'BELGIUM',
  FRANCE = 'FRANCE',
  GERMANY = 'GERMANY',
  ITALY = 'ITALY',
  LUXEMBOURG = 'LUXEMBOURG',
  NETHERLANDS = 'NETHERLANDS',
  SLOVAKIA = 'SLOVAKIA',
  SPAIN = 'SPAIN',
  SWITZERLAND = 'SWITZERLAND',
}
