import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { ReactNode } from 'react'

import Button from '../Button'
import MenuItem from '../MenuItem'

interface Props {
  children: ReactNode
  onClick: () => void
}

const useStyles = makeStyles({
  menuItem: {
    cursor: 'pointer',
    padding: 0,
    height: 48,
  },
  button: {
    borderRadius: 0,
    height: '100%',
  },
})

const AutoCompleteAction: React.FC<React.PropsWithChildren<Props>> = ({ onClick, children }) => {
  const classes = useStyles()
  return (
    <MenuItem className={classes.menuItem} disableGutters onClick={onClick}>
      <Button variant="primary" className={classes.button} fullWidth>
        {children}
      </Button>
    </MenuItem>
  )
}

export default AutoCompleteAction
