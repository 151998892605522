import combine from '@eversports/core-io/combine'
import * as s from '@eversports/core-io/sanitizer'
import * as v from '@eversports/core-io/validator'

export default combine({
  sanitizer: s.sanitizer({
    userAgreedToAllTermsAndConditions: [s.string()],
    firstname: s.string(),
    lastname: s.string(),
    role: s.string(),
  }),
  validator: v.validator({
    userAgreedToAllTermsAndConditions: v.array({ min: 3, max: 3 }),
    firstname: v.length({ min: 2 }),
    lastname: v.length({ min: 2 }),
    role: v.length({ min: 2 }),
  }),
  fieldNameIds: {
    userAgreedToAllTermsAndConditions: 'user-agreed-to-all-terms-and-conditions',
    firstname: 'user-first-name',
    lastname: 'user-last-name',
    role: 'registration-terms-and-conditions-role',
  },
})
