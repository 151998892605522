import { Theme } from '@eversports/react-components/design-tokens/theme'
import { assertNever } from '@eversports/utility-types'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { CountryName } from './countries'
import AustriaFlag from './flags/austria.svg'
import BelgiumFlag from './flags/belgium.svg'
import FranceFlag from './flags/france.svg'
import GermanyFlag from './flags/germany.svg'
import ItalyFlag from './flags/italy.svg'
import NetherlandsFlag from './flags/netherlands.svg'
import SlovakiaFlag from './flags/slovakia.svg'
import SpainFlag from './flags/spain.svg'
import SwissFlag from './flags/switzerland.svg'
import LuxembourgFlag from './flags/luxembourg.svg'

interface Props {
  country: CountryName
}

const useStyles = makeStyles(({ fontSizing }: Theme) => ({
  flagIcon: {
    height: fontSizing.default,
    maxWidth: fontSizing.default,
  },
}))

const PhoneNumberFieldCountryCallingCodeIcon: React.FC<React.PropsWithChildren<Props>> = ({ country }) => {
  const classes = useStyles()
  switch (country) {
    case CountryName.AUSTRIA:
      return <img src={AustriaFlag} className={classes.flagIcon} />
    case CountryName.BELGIUM:
      return <img src={BelgiumFlag} className={classes.flagIcon} />
    case CountryName.GERMANY:
      return <img src={GermanyFlag} className={classes.flagIcon} />
    case CountryName.FRANCE:
      return <img src={FranceFlag} className={classes.flagIcon} />
    case CountryName.SPAIN:
      return <img src={SpainFlag} className={classes.flagIcon} />
    case CountryName.ITALY:
      return <img src={ItalyFlag} className={classes.flagIcon} />
    case CountryName.LUXEMBOURG:
      return <img src={LuxembourgFlag} className={classes.flagIcon} />
    case CountryName.NETHERLANDS:
      return <img src={NetherlandsFlag} className={classes.flagIcon} />
    case CountryName.SLOVAKIA:
      return <img src={SlovakiaFlag} className={classes.flagIcon} />
    case CountryName.SWITZERLAND:
      return <img src={SwissFlag} className={classes.flagIcon} />
    default:
      return assertNever(country)
  }
}

export default PhoneNumberFieldCountryCallingCodeIcon
