import combine from '@eversports/core-io/combine'
import * as s from '@eversports/core-io/sanitizer'
import * as v from '@eversports/core-io/validator'

export default combine({
  sanitizer: s.sanitizer({
    companyName: s.string(),
    studioTypes: [s.string()],
    country: s.string(),
    website: s.optional(s.string()),
  }),
  validator: v.validator({
    companyName: v.length({ min: 2 }),
    studioTypes: v.array({ min: 1 }),
    country: v.length({ min: 2 }),
  }),
  fieldNameIds: {
    companyName: 'company-name',
    studioTypes: 'studio-types',
    country: 'address-country',
    website: 'website',
  },
})
