import combine from '@eversports/core-io/combine'
import * as s from '@eversports/core-io/sanitizer'
import * as v from '@eversports/core-io/validator'

export default combine({
  sanitizer: s.sanitizer({
    name: s.string(),
    streetNumber: s.string(),
    street: s.string(),
    zip: s.string(),
    country: s.string(),
    city: s.string(),
    longitude: s.optional(s.number()),
    latitude: s.optional(s.number()),
    website: s.optional(s.string()),
  }),
  validator: v.validator({
    name: v.length({ min: 2 }),
    streetNumber: v.length({ min: 1 }),
    street: v.length({ min: 2 }),
    zip: v.length({ min: 2 }),
    country: v.length({ min: 2 }),
    city: v.length({ min: 2 }),
  }),
  fieldNameIds: {
    name: 'registration-venue-studio-name',
    streetNumber: 'address-number',
    street: 'address-street',
    zip: 'address-zip',
    country: 'address-country',
    city: 'address-city',
    website: 'website',
  },
})
