import Button from '@eversports/react-components/primitives/Button'
import Divider from '@eversports/react-components/primitives/Divider'
import { SimpleFlex, VerticalFlex } from '@eversports/react-components/primitives/Flex'
import React from 'react'

import { Localized } from '../localization/react'

interface Props {
  isForwardActionDisabled: boolean
  onGoBack?: () => void
  isCompletionStep?: boolean
}

const StepperActions: React.FC<React.PropsWithChildren<Props>> = ({
  onGoBack,
  isCompletionStep,
  isForwardActionDisabled,
}) => (
  <VerticalFlex verticalSpacing="wide">
    <Divider />
    <SimpleFlex justifyContent="space-between">
      <div>
        {onGoBack && (
          <Button variant="secondary" onClick={onGoBack}>
            <Localized id="stepper-action-back" />
          </Button>
        )}
      </div>
      <Button type="submit" variant="primary" disabled={isForwardActionDisabled}>
        {isCompletionStep ? (
          <Localized id="stepper-action-complete-registration" />
        ) : (
          <Localized id="stepper-action-continue" />
        )}
      </Button>
    </SimpleFlex>
  </VerticalFlex>
)

export default StepperActions
