import Chip from '@mui/material/Chip'
import { default as MuiSelect, SelectProps } from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { Localized } from '../../localization/react'
import useTheme from '../../design-tokens/useTheme'
import Flex from '../Flex'

// Sizes applied to the Menu element.
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export interface MultiSelectValue {
  id: string
  value: string
}

interface Props extends Omit<SelectProps, 'MenuProps'> {
  className?: string
  multipleSelectRenderValues?: Array<MultiSelectValue>
}

const useStyles = makeStyles({
  chip: {
    margin: 2,
  },
  noOption: {
    padding: '5px',
    boxSizing: 'border-box',
  },
  selectRoot: {
    height: ITEM_HEIGHT,
    padding: 0,
  },
  selectContent: {
    height: '100%',
    overflow: 'hidden',
  },
})

const SelectMultiple: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  multipleSelectRenderValues,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <MuiSelect
      renderValue={(renderValues) => {
        if (Array.isArray(renderValues) && multipleSelectRenderValues) {
          const selectedValues = multipleSelectRenderValues.filter((multipleSelectRenderValue) =>
            renderValues.includes(multipleSelectRenderValue.id),
          )
          return (
            <Flex
              flexWrap="nowrap"
              paddingRight="dense"
              paddingLeft="dense"
              alignItems="center"
              className={classes.selectContent}
            >
              {selectedValues.length > 0 ? (
                selectedValues.map((selectedValue) => (
                  <Chip key={selectedValue.id} label={selectedValue.value} size="small" className={classes.chip} />
                ))
              ) : (
                <span className={classes.noOption}>
                  <Localized id="no-option" />
                </span>
              )}
            </Flex>
          )
        }

        return undefined
      }}
      {...props}
      classes={{
        select: classes.selectRoot,
      }}
      MenuProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 9.7 + ITEM_PADDING_TOP,
          marginTop: 5,
        },
        elevation: 0,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        PaperProps: {
          style: {
            boxShadow: theme.shadows[4],
          },
        },
      }}
      multiple
    >
      {children}
    </MuiSelect>
  )
}

export default SelectMultiple
