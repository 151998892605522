import { useApolloClient } from '@apollo/client'
import { Theme } from '@eversports/react-components/design-tokens/theme'
import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import Flex from '@eversports/react-components/primitives/Flex'
import Placeholder from '@eversports/react-components/primitives/Placeholder'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { makeStyles } from '@mui/styles'
import { parse } from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import RegistrationContext from '../../registration-context'

import InvalidURL from '../InvalidURL'
import Stepper from '../Stepper'

import validateAndLoadByToken from './validate-and-load-by-token'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  content: {
    margin: '0 auto',
    maxWidth: '100%',
    [breakpoints.up('md')]: {
      maxWidth: 1400,
    },
  },
  innerContent: {
    maxWidth: '100%',
    [breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },
}))

const MatchedRegistrationRouteLayout: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({
  children,
  location,
}) => {
  const classes = useStyles()
  const { token } = parse(location.search)
  const [isValidToken, setIsValidToken] = useState(true)
  const [isLoading, setIsLoading] = useState<boolean>()
  const registrationContext = useContext(RegistrationContext)
  const client = useApolloClient()

  if (!registrationContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  useEffect(() => {
    checkToken().catch((error) => {
      ClientLogger.error('Token validation Failed', error)
    })
  }, [token])

  const checkToken = async () => {
    if (!token) {
      setIsValidToken(false)
      return
    }
    const result = await validateAndLoadByToken(client)({ token: String(token) })
    if (result.isError) {
      ClientLogger.error('Token validation Failed', result.error)
    } else if (result.value.__typename === 'ValidateAndLoadByTokenResult') {
      setIsValidToken(result.value.isValid)
      registrationContext.setUserAdminInformation({
        ...registrationContext.userAdminInformation,
        email: result.value.customerAdminMail!,
      })
    }
    setIsLoading(false)
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <Placeholder height={250} width="100%" center>
          <CircularProgress />
        </Placeholder>
      )
    }

    if (!isValidToken) {
      return <InvalidURL />
    }

    return (
      <>
        <Stepper />
        <Flex flexDirection="column" className={classes.innerContent} fullWidth>
          {children}
        </Flex>
      </>
    )
  }

  return (
    <Flex
      className={classes.content}
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      padding="default"
      paddingBottom="wide"
      verticalSpacing="wide"
      flexGrow={1}
      flexShrink={0}
    >
      {renderContent()}
    </Flex>
  )
}

export default withRouter(MatchedRegistrationRouteLayout)
