export interface SingleIOError {
  path: Array<string>
  id: string
  message?: string
  value?: unknown
  params?: any
  cause?: Error
  fieldNameId?: string
}

export function isIOError(error: any): error is IOError {
  return (error as IOError) && (error as IOError).name === 'IOError' && Array.isArray((error as IOError).errors)
}

export class IOError extends Error {
  public static single(id: string, value?: unknown, params?: any): IOError {
    const err = new IOError()
    err.errors.push({
      path: [],
      id,
      value,
      params,
      cause: undefined,
    })
    return err
  }

  public static factory(id: string, params?: any): (value?: unknown) => IOError {
    return (value) => {
      const err = new IOError()
      err.errors.push({
        path: [],
        id,
        value,
        params,
        cause: undefined,
      })
      return err
    }
  }

  public name = 'IOError'
  // NOTE(swatinem): this is only used when you do a `console.log` of the error.
  // the message here is never exposed to clients!
  public get message() {
    const messages = this.errors.map((err) => {
      const prefix = err.path.length ? err.path.join('.') + ': ' : ''
      return prefix + err.id
    })
    if (messages.length === 1) {
      return messages[0]
    }
    return `  * ${messages.join('\n  * ')}`
  }

  public errors: Array<SingleIOError> = []

  public addChildErrors(path: string, child: IOError, fieldNameIds?: Record<string, string>) {
    for (const error of child.errors) {
      if (path) {
        error.path.unshift(path)
      }
      if (fieldNameIds && error.path.length === 1) {
        error.fieldNameId = fieldNameIds[path]
      }
      this.errors.push(error)
    }
  }

  public withPath(path: string) {
    const newError = new IOError()
    newError.addChildErrors(path, this)
    return newError
  }
}
