import { mapIOErrorToLocalizedObject } from '@eversports/core-io/helpers'
import { isIOError } from '@eversports/io-error'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { throttledPyroFormValidator } from '@eversports/react-components/utilities/pyro-form-validate'
import GoogleRecaptcha from '../../components/GoogleRecaptcha'
import PhoneNumberField from '../../components/PhoneNumberField'
import StepperActions from '../../components/StepperActions'
import TextFieldPassword from '../../components/TextFieldPassword'
import FreeTrialContext, { FreeTrialCreateAnAdminUserProps } from '../../free-trial-context'
import { getSharedConfig } from '../../get-config'
import { Localized, useIntl } from '../../localization/react'
import { FreeTrialRoutes } from '../../routes'

import check from './check'

const useStyles = makeStyles({
  field: {
    width: '50%',
  },
})

const FreeTrialCreateAnAdminUser: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({ history }) => {
  const classes = useStyles()
  const intl = useIntl()
  const freeTrialContext = useContext(FreeTrialContext)
  const [isRecaptchaResponseSuccessful, setIsRecaptchaResponseSuccessful] = useState<boolean>()

  if (!freeTrialContext) {
    throw new Error('The wrapping context was not correctly defined')
  }

  const handleValidate = throttledPyroFormValidator((values: FreeTrialCreateAnAdminUserProps) => {
    try {
      check(values)
    } catch (e: any) {
      if (isIOError(e)) {
        return mapIOErrorToLocalizedObject(e, intl)
      }
      ClientLogger.error('Free trial admin user creation failed', e)
    }
    return {}
  })

  const handleForwardStep = (values: FreeTrialCreateAnAdminUserProps) => {
    freeTrialContext.setCreateAnAdminUserValues(values)
    history.push(FreeTrialRoutes.COMPLETE_SET_UP)
  }

  const handleBackStep = () => {
    history.push(FreeTrialRoutes.VENUE)
  }

  return (
    <Flex flexDirection="column" verticalSpacing="wide" fullWidth>
      <Flex flexDirection="column" verticalSpacing="dense">
        <Heading is="h2">
          <Localized id="free-trial-title" />
        </Heading>
        <Text color="subtleText">
          <Localized id="free-trial-subheading" />
        </Text>
      </Flex>
      <PyroForm
        initialValues={freeTrialContext.createAnAdminUserValues}
        onValidate={handleValidate}
        onSubmit={handleForwardStep}
      >
        {({ hasErrors }) => (
          <Form>
            <Flex flexDirection="column" verticalSpacing="wide">
              <Flex horizontalSpacing="default" fullWidth>
                <Flex className={classes.field}>
                  <TextField name="firstName" label={<Localized id="user-first-name" />} fullWidth />
                </Flex>
                <Flex className={classes.field}>
                  <TextField name="lastName" label={<Localized id="user-last-name" />} fullWidth />
                </Flex>
              </Flex>
              <PhoneNumberField />
              <TextField
                name="email"
                label={<Localized id="user-email" />}
                helperText={<Localized id="free-trial-create-an-admin-user-email-info" />}
                fullWidth
              />
              <TextFieldPassword />
              <TextField
                name="question"
                label={<Localized id="free-trial-create-an-admin-user-question" />}
                fullWidth
              />
              <GoogleRecaptcha
                siteKey={getSharedConfig().GOOGLE_RECAPTCHA_SITE_KEY}
                onSuccessfulResponse={() => {
                  setIsRecaptchaResponseSuccessful(true)
                }}
              />
              <StepperActions
                isForwardActionDisabled={hasErrors || !isRecaptchaResponseSuccessful}
                onGoBack={handleBackStep}
              />
            </Flex>
          </Form>
        )}
      </PyroForm>
    </Flex>
  )
}

export default withRouter(FreeTrialCreateAnAdminUser)
