import * as React from 'react'

import useScript from '../useScript'

import getContextValue from './get-context-value'
import GoogleMapsAPIContext from './GoogleMapsAPIContext'

export enum GoogleMapsAPILibrary {
  PLACES = 'places',
  GEOCODE = 'geocode',
}

interface Props {
  apiKey: string
  language: string
  libraries: Array<GoogleMapsAPILibrary>
}

const GoogleMapsAPI: React.FC<React.PropsWithChildren<Props>> = ({ children, apiKey, libraries, language }) => {
  const [googleMapsAPILoaded, googleMapsAPIHasError] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}&language=${language}`,
  })

  return (
    <GoogleMapsAPIContext.Provider
      value={getContextValue({ loaded: googleMapsAPILoaded, error: googleMapsAPIHasError })}
    >
      {children}
    </GoogleMapsAPIContext.Provider>
  )
}

export default GoogleMapsAPI
