import { ApolloClient } from '@apollo/client'
import { AsyncResult, failure, success } from '../../result'
import {
  ValidateAndLoadByTokenDocument,
  ValidateAndLoadByTokenMutation,
  ValidateAndLoadByTokenMutationVariables,
} from '../../graphql'

const validateAndLoadByToken =
  (client: ApolloClient<unknown>) =>
  async ({
    token,
  }: ValidateAndLoadByTokenMutationVariables): AsyncResult<
    ValidateAndLoadByTokenMutation['validateAndLoadByToken']
  > => {
    const result = await client.mutate<ValidateAndLoadByTokenMutation, ValidateAndLoadByTokenMutationVariables>({
      mutation: ValidateAndLoadByTokenDocument,
      variables: {
        token,
      },
    })

    if (!result.data || !result.data.validateAndLoadByToken) {
      return failure(new Error('No data is being returned from mutation'))
    }

    if (result.data.validateAndLoadByToken.__typename === 'ExpectedErrors') {
      // TODO: Implement some error handling. No idea how
      return failure(
        new Error('An expected error happened: ' + JSON.stringify(result.data.validateAndLoadByToken.errors)),
      )
    }

    return success(result.data.validateAndLoadByToken)
  }

export default validateAndLoadByToken
